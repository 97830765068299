import { Injectable } from '@angular/core';

import { Socket } from 'ngx-socket-io';
import {Observable, of} from 'rxjs';
import test from "node:test";
import {IMessageListDto} from "../models/chat/messageList.model";

@Injectable({
  providedIn: 'root'
})

export class ChatSocketService {

  constructor(private socket: Socket) { }

  emitUser(data):Observable<any>  {
    let success = this.socket.emit('joinRoom', data);
    this.emitOn();
    return success;
  }

  emitOn() {
    return of( this.socket.on('joinRoom',function (data) {
      return data;
    }));
  }

  sendMessage(data):Observable<any> {
    return of(this.socket.emit('newMessageSent', data));
    // this.sendMessageEmitOn()
  }

  sendMessageEmitOn() {
    return of( this.socket.on('newMessageSent',function (data) {
      return data;
    }));
  }

  getMessages(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('newMessageReceived', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    })
  }


  sendNotificationFlag(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('sendNotificationFlag', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    })
  }



  connectUser(userId):Observable<any>  {
    let data= {
      user_id: userId
    };
    let success = this.socket.emit('connectUser', data);
    this.connectUserEmitOn()
    return success;
  }

  connectUserEmitOn() {
    return of( this.socket.on('connectUser',function (data) {
      return data;
    }));
  }

}

<section class="login-secction">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-6 loginLeftcontent text-center d-flex align-items-center" style="background-image: url('../assets/images/login-left-img.jpg')">
        <div class="loginContentvrCenter">
          <h3>Welcome Back!</h3>
          <!-- <p>Contrary to popular belief, Lorem Ipsum There are many variation</p> -->
          <br />
          <a routerLink="/auth/login" class="btn btn-theme w-100">Sign In</a>
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center">
        <div class="loginRightcontent w-100">
          <h1 class="loginTitle text-center">Let’s Get Started</h1>
          <h4 class="text-center">Create An Account</h4>

          <form class="form-horizontal mt-4" [formGroup]="registerForm" (ngSubmit)="signUp()">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-floating form-group">
                  <input type="text" class="form-control" id="first_name" placeholder="First name" minlength="2" maxlength="25" formControlName="first_name" />
                  <label for="first_name">First name <span class="require">*</span></label>
                  <div *ngIf="f['first_name'].touched && f['first_name'].hasError('required')" class="alert alert-danger">First Name is required.</div>
                  <div *ngIf="f['first_name'].touched && f['first_name'].hasError('whitespace')" class="alert alert-danger">Please enter valid First Name.</div>
                  <div *ngIf="f['first_name'].touched && f['first_name'].hasError('minlength')" class="alert alert-danger">Minimum 2 characters are required.</div>
                  <div *ngIf="f['first_name'].touched && f['first_name'].hasError('maxlength')" class="alert alert-danger">More than 25 characters are not allowed.</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-floating form-group">
                  <input type="text" class="form-control" id="last_name" placeholder="Last name" minlength="2" maxlength="25" formControlName="last_name" />
                  <label for="last_name">Last name <span class="require">*</span></label>
                  <div *ngIf="f['last_name'].touched && f['last_name'].hasError('required')" class="alert alert-danger">Last Name is required.</div>
                  <div *ngIf="f['last_name'].touched && f['last_name'].hasError('whitespace')" class="alert alert-danger">Please enter valid Last Name.</div>
                  <div *ngIf="f['last_name'].touched && f['last_name'].hasError('minlength')" class="alert alert-danger">Minimum 2 characters are required.</div>
                  <div *ngIf="f['last_name'].touched && f['last_name'].hasError('maxlength')" class="alert alert-danger">More than 25 characters are not allowed.</div>
                </div>
              </div>
              <!-- <div class="col-12">
                                <div class="form-floating form-group">
                                    <input type="text" class="form-control" id="post_code" placeholder="Post code" formControlName="post_code">
                                    <label for="post_code">Post code <span class="require">*</span></label>
                                    <div *ngIf="f['post_code'].touched && f['post_code'].hasError('required')" class="alert alert-danger">
                                      Post Code is required.
                                    </div>
                                    <div *ngIf="f['post_code'].touched && f['post_code'].hasError('whitespace')" class="alert alert-danger">
                                      Please enter valid Post Code.
                                    </div>
                                    <div *ngIf="f['post_code'].touched && f['post_code'].hasError('minlength')" class="alert alert-danger">
                                      Minimum 4 characters are required.
                                    </div>
                                    <div *ngIf="f['post_code'].touched && f['post_code'].hasError('maxlength')" class="alert alert-danger">
                                      More than 8 characters are not allowed.
                                    </div>
                                </div>
                            </div> -->
              <div class="col-12">
                <div class="form-floating form-group">
                  <input type="email" class="form-control" id="email" placeholder="name@example.com" formControlName="email" />
                  <label for="email">Email address <span class="require">*</span></label>
                  <div *ngIf="f['email'].touched && !f['email'].hasError('email') && f['email'].hasError('required')" class="alert alert-danger">Email Address is required.</div>
                  <div *ngIf="f['email'].hasError('email') && !f['email'].hasError('required')" class="alert alert-danger">Please enter a valid Email Address.</div>
                  <div *ngIf="f['email'].touched && f['email'].hasError('maxlength')" class="alert alert-danger">More than 100 characters are not allowed.</div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating form-group">
                  <input type="password" class="form-control" id="password1" placeholder="Password" formControlName="password" />
                  <label for="password1">Password <span class="require">*</span></label>
                  <div *ngIf="f['password'].touched && f['password'].hasError('required')" class="alert alert-danger">Password is required.</div>
                  <div *ngIf="f['password'].touched && f['password'].hasError('whitespace')" class="alert alert-danger">Please enter valid password.</div>
                  <div *ngIf="f['password'].touched && f['password'].hasError('maxlength')" class="alert alert-danger">More than 100 characters are not allowed.</div>
                  <div *ngIf="f['password'].touched && f['password'].hasError('minlength')" class="alert alert-danger">Min 8 characters are required.</div>
                  <span
                    class="EyeShowHide"
                    onclick="if($('#password1').attr('type') == 'password'){$('#password1').attr('type','text');}else{$('#password1').attr('type','password');}$('.eye-show-password').toggle();$('.eye-hide-password').toggle()">
                    <img src="./assets/images/eye-hide.svg" class="eye-show-password" />
                    <img src="./assets/images/eye-show.svg" class="eye-hide-password" style="display: none" />
                  </span>
                </div>
              </div>
              <div class="col-12">
                <div class="form-floating form-group">
                  <input type="password" class="form-control" id="confirm_password1" placeholder="Confirm Password" formControlName="confirm_password" />
                  <label for="confirm_password1">Confirm Password <span class="require">*</span></label>
                  <div *ngIf="f['confirm_password'].touched && f['confirm_password'].hasError('required')" class="alert alert-danger">Confirm Password is required.</div>
                  <div *ngIf="passwordMatchError && !f['confirm_password'].hasError('required')" class="alert alert-danger">Confirm Password does not match.</div>
                  <div *ngIf="f['confirm_password'].touched && f['confirm_password'].hasError('whitespace')" class="alert alert-danger">Please enter valid confirm Password.</div>
                  <div *ngIf="f['confirm_password'].touched && f['confirm_password'].hasError('maxlength')" class="alert alert-danger">
                    More than 100 characters are not allowed.
                  </div>
                  <div *ngIf="f['confirm_password'].touched && f['confirm_password'].hasError('minlength')" class="alert alert-danger">Min 8 characters are required.</div>
                  <span
                    class="EyeShowHide"
                    onclick="if($('#confirm_password1').attr('type') == 'password'){$('#confirm_password1').attr('type','text');}else{$('#confirm_password1').attr('type','password');}$('.eye-show-confirm_password').toggle();$('.eye-hide-confirm_password').toggle()">
                    <img src="./assets/images/eye-hide.svg" class="eye-show-confirm_password" />
                    <img src="./assets/images/eye-show.svg" class="eye-hide-confirm_password" style="display: none" />
                  </span>
                </div>
              </div>

              <div class="col-12 mb-3">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="privacy_terms" formControlName="privacy_terms" />
                  <label class="form-check-label" for="privacy_terms"
                    >I have read and agree to <a routerLink="/pages/terms-and-conditions" class="text-dark">Terms of Services</a> and
                    <a routerLink="/pages/privacy-policy" class="text-dark">Privacy Policy </a>
                  </label>
                  <div *ngIf="f['privacy_terms'].touched && f['privacy_terms'].hasError('required')" class="alert alert-danger">
                    You must be agreed to Terms of Services and Privacy Policy
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center pt-3">
              <button class="btn btn-theme w-100" type="submit" [disabled]="registerForm.invalid || !f['privacy_terms'].value">Sign Up</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

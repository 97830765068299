import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from "../service/alert/alert.service";
import { AuthService } from "../../components/auth/auth.service";
import { LocalStoreService } from "../service/local-store.service";
import { LoaderService } from "../service/loader.service";


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor( private loaderService: LoaderService,
               private alertService: AlertService,
               private authService: AuthService,
               private ls: LocalStoreService
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    if (!req.url.includes('i18n')) {
      this.loaderService.isLoading.next(true);
    }
    return Observable.create(
      (observer: { next: (arg0: HttpResponse<any>) => void; error: (arg0: any) => void; complete: () => void }) => {
        const subscription = next.handle(req).subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            console.log(err);
            if (err.status == 401 || err.status == 403) {
              this.alertService.errorAlert("Session Expired!");
              this.authService.signout(401);
            }
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          }
        );
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      }
    );
  }
}

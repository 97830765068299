<footer class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-5 footerLogocontent pb-3">
                <a class="footer-brand mb-3" routerLink="/"><img src="../assets/images/logo.svg" width="200" alt="Riot Rent"></a>
                <div class="socialIcon pt-lg-5 pt-4">
                    <a href="#" class="iconSocial"><svg xmlns="http://www.w3.org/2000/svg" width="47.242" height="47.242" viewBox="0 0 47.242 47.242"><path id="facebook" d="M54.621,31A23.621,23.621,0,1,0,78.242,54.621,23.616,23.616,0,0,0,54.621,31ZM61.4,45.813s-2.5-.021-3.128-.021A1.614,1.614,0,0,0,56.7,47.44v3.475h4.7l-.535,4.735h-4.22V67.744H51.871V55.713H47.839v-4.85h4.094V46.285c0-2.551,2.835-4.8,4.808-4.8.294,0,4.661.042,4.661.042Z" transform="translate(-31 -31)" fill="#fff"/></svg></a>
                    <a href="https://twitter.com/RiotRent_com" target="_blank" class="iconSocial"><svg xmlns="http://www.w3.org/2000/svg" width="46.998" height="46.999" viewBox="0 0 46.998 46.999">
                      <path id="twitter" d="M16870.578,577.078a23.5,23.5,0,1,1,16.617-6.884A23.338,23.338,0,0,1,16870.578,577.078Zm-1.658-20.279h0l5.418,7.864h7.373l-8.691-12.622,8.158-9.463h-3.143l-6.414,7.437-5.123-7.437h-7.373l8.4,12.19-8.539,9.9h3.148l6.789-7.864Zm8.383,5.558h-1.582l-12.2-17.409h1.578l12.2,17.407Z" transform="translate(-16847.08 -530.08)" fill="#fff"/>
                    </svg></a>
                    <a href="#" class="iconSocial"><svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="47.242" height="47.242" viewBox="0 0 47.242 47.242"> <circle id="Ellipse_5" data-name="Ellipse 5" cx="3.76" cy="3.76" r="3.76" transform="translate(19.861 19.861)" fill="#fff"/><path id="Path_23" data-name="Path 23" d="M59.953,45.65H50.336a4.7,4.7,0,0,0-4.7,4.7V59.96a4.7,4.7,0,0,0,4.7,4.7h9.617a4.7,4.7,0,0,0,4.7-4.7V50.343A4.7,4.7,0,0,0,59.953,45.65ZM55.144,61.306A6.155,6.155,0,1,1,61.3,55.152,6.155,6.155,0,0,1,55.144,61.306Zm6.189-10.788A1.521,1.521,0,1,1,62.852,49,1.521,1.521,0,0,1,61.333,50.518Z" transform="translate(-31.523 -31.53)" fill="#fff"/> <path id="Path_24" data-name="Path 24" d="M23.621,0A23.621,23.621,0,1,0,47.242,23.621,23.621,23.621,0,0,0,23.621,0ZM35.432,28.4A7.043,7.043,0,0,1,28.4,35.432H18.847A7.046,7.046,0,0,1,11.811,28.4V18.847a7.046,7.046,0,0,1,7.037-7.037H28.4a7.046,7.046,0,0,1,7.037,7.037Z" fill="#fff"/></svg></a>
                </div>
            </div>
            <div class="col-lg-5 col-md-7 d-sm-flex">
                <div class="footerMenu" *ngIf="isLoggedIn && !isAdmin">
                  <a routerLink="property/my-bids" >My Bids </a>
                    <a routerLink="property/list">My listing </a>
                    <a routerLink="chat">Chat</a>
                </div>
                <div class="footerMenu">
                    <a routerLink="/pages/FAQ">FAQ </a>
                    <a routerLink="contact-us">Contact us </a>
                </div>
                <div class="footerMenu menuTerms">
                    <a routerLink="/pages/terms-and-conditions">Terms and Conditions </a>
                    <a routerLink="/pages/privacy-policy">Privacy Policy </a>
                </div>
            </div>
            <div class="col-lg-4 pt-lg-0 pt-4">
                <div class="contactInfo d-flex align-items-start mb-4">
                    <svg class="mt-2" xmlns="http://www.w3.org/2000/svg" width="22" height="30.004" viewBox="0 0 22 30.004">
                        <g id="Group_73" data-name="Group 73" transform="translate(-5 -1)">
                          <path id="Path_26" data-name="Path 26" d="M16,1A11,11,0,0,0,5,12c0,5.81,9.8,18.1,10.22,18.63a1,1,0,0,0,1.56,0C17.2,30.1,27,17.81,27,12A11,11,0,0,0,16,1Zm0,14a4,4,0,1,1,4-4A4,4,0,0,1,16,15Z" fill="#fff"/>
                        </g>
                    </svg>
                    <div class="ms-3 text-white" style="line-height:28px;">
                      <p>DataDollar Holdings Ltd, <br/>
                      406a Kings Road, SW0 0LJ <br/>
                      London, United Kingdom</p>
                    </div>
                </div>
<!--                <div class="contactInfo d-flex align-items-center ">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 26.561 26.617">-->
<!--                        <path id="Path_27" data-name="Path 27" d="M26.4,19.534,22.69,15.82a2.471,2.471,0,0,0-4.113.929,2.528,2.528,0,0,1-2.919,1.592c-2.653-.663-6.235-4.113-6.9-6.9a2.4,2.4,0,0,1,1.592-2.919,2.471,2.471,0,0,0,.929-4.113L7.566.7A2.65,2.65,0,0,0,3.984.7L1.464,3.217c-2.521,2.653.265,9.684,6.5,15.919s13.266,9.154,15.919,6.5L26.4,23.116A2.649,2.649,0,0,0,26.4,19.534Z" transform="translate(-0.539 0)" fill="#fff"/>-->
<!--                    </svg>-->
<!--                    <a href="telto:963-635-2739" class="ms-3 text-white text-dacoration-none">(963) 635-2739</a>-->
<!--                </div>-->
            </div>
        </div>
        <div class="text-center pt-lg-5 pt-4">
            <p class="copyright text-white mb-0">Copyright © {{this.data}} Riot Rent. All rights reserved.</p>
        </div>
    </div>
</footer>

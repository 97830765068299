//#region imports
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { CustomValidators } from '../../../shared/classes/CustomValidatorsClass';
import { AlertService } from "../../../shared/service/alert/alert.service";

//#endregion imports
@Component({
  selector: 'app-sidebar-login',
  templateUrl: './sidebar-login.component.html',
  styleUrls: ['./sidebar-login.component.scss']
})
export class SidebarLoginComponent implements OnInit {
//#region public variables
  registerForm: FormGroup;
  loginForm: FormGroup;
//#endregion public variables

  constructor(
    private authService: AuthService,
    private router: Router,
    private alert:AlertService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Getter methods
  // -----------------------------------------------------------------------------------------------------

  get passwordMatchError() {
    return (
      this.registerForm.getError('mismatch') &&
      this.registerForm.get('confirm_password')?.touched
    );
  }
  get lf() {
    return this.loginForm.controls;
  }
  get f() {
    return this.registerForm.controls;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8),Validators.maxLength(100)]),
    });
    this.registerForm = new FormGroup({
        first_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25),this.noWhitespaceValidator]),
        last_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25),this.noWhitespaceValidator]),
    //    post_code: new FormControl('', [Validators.required,Validators.minLength(4), Validators.maxLength(8),this.noWhitespaceValidator]),
        email: new FormControl('', [Validators.required, Validators.email,Validators.maxLength(100),this.noWhitespaceValidator]),
        password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(100),this.noWhitespaceValidator]),
        confirm_password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]),
        privacy_terms: new FormControl(null, [Validators.required])
      },
      [CustomValidators.MatchValidator('password', 'confirm_password')]
    )
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public signUp() {
    if (this.registerForm.valid) {
      let data = {
        first_name: this.f['first_name'].value,
        last_name: this.f['last_name'].value,
      //  post_code: this.f['post_code'].value,
        email: this.f['email'].value,
        password: this.f['password'].value,
        confirm_password: this.f['confirm_password'].value
      };
      this.authService.signUp(data).subscribe((res: any) => {
        if(res.status){
          this.alert.successAlert(res.message);
          this.registerForm.reset();
        }else{
          this.alert.errorAlert(res.message);
        }
      })
    }
  }
  public signIn() {
    if (this.loginForm.valid) {
      let data = {
        email: this.lf['email'].value,
        password: this.lf['password'].value,
      };
      this.authService.signIn(data).subscribe((res: any) => {
        if (res.status) {
          this.alert.successAlert(res.message);
          if(res.data.user_type){
            window.location.href=window.location.origin+'/admin/dashboard';
          } else{
            window.location.href=window.location.origin;
          }
        } else {
          this.alert.errorAlert(res.message);
        }
      });
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
}

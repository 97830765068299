import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../../../components/property/property.service';
import { IQuerySpecsDto, QuerySpecs } from '../../models/common/query.model';
import { IList, IListDetail } from '../../models/property/list';
import { AlertService } from '../../service/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { paginatorConfig } from 'src/app/shared/configs/paginator.Configs';
import { PageEvent } from '@angular/material/paginator';
import { AuthService } from '../../../components/auth/auth.service';
import { ConfirmationDialogComponent, ConfirmDialogModel } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RealtimeSocketService } from "../../service/realtime-socket.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss'],
})
export class PropertyListComponent {
  columnsToDisplay = ['id'];
  query: IQuerySpecsDto = new QuerySpecs();
  propertyDetails: IList;
  pageSize: number;
  paginatorConfig = paginatorConfig;
  isUserLogin = false;
  myList = false;
  featured = false;
  subscriptionReal:Subscription;
  socketPropertyList = [];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private propertyService: PropertyService,
    private alertService: AlertService,
    private activeRoute: ActivatedRoute,
    private propertyListService: PropertyService,
    private authService: AuthService,
    private realPropertySocket: RealtimeSocketService,
    private socketReal: RealtimeSocketService

  ) {
    this.query = new QuerySpecs();
    this.pageSize = this.query.limit;

    /**
     * check is login and is user
     */
    if (this.authService.isLoggedIn()) {
      if (!this.authService.isAdmin()) {
        this.isUserLogin = true;
      }
      /**
       * check is my list page request of user
       */
      if (this.activeRoute.snapshot.data['myList']) {
        this.myList = true;
      }
    }
    if (this.activeRoute.snapshot.data['featured']) {
      this.featured = true;
      this.query.is_featured = 1;
    }
    /**
     * set data from another component form
     */
    this.propertyListService.query$.subscribe(res => {
      this.query.property_type = res.property_type;
      this.query.number_of_beds = res.number_of_beds;
      this.query.price_type = res.price_type;
      this.query.available_date = res.available_date;
      this.query.search = res.search;
      this.query.city = res.city;
      this.query.location = res.location;
      this.query.radius = res.radius;
      this.query.lat = res.lat != undefined?res.lat:null;
      this.query.lng = res.lng != undefined?res.lng:null;
      this._getData(this.query);
    });


    this.realTimePropertyUpdate();
  }

  /**
   *
   * @param event
   * handle pagination event
   */
  handlePageEvent(event: PageEvent) {
    this.query.offset = event.pageIndex * this.query.limit;
    this.query.sorted_by = '';
    this._getData(this.query);
  }

  /**
   * get data from api property list
   * @param query
   * @private
   */
  private _getData(query: IQuerySpecsDto) {
    if (this.myList) {
      this.propertyService.getMyPropertyList(query).subscribe(res => {
        if (res.status == 1) {
          this.propertyDetails = res.data;
          this.propertyDetails.property_list.forEach(obj =>{
            this.countDown(obj);
            this.realPropertySocket.connectProperty(obj.id);
          });
        } else {
          this.alertService.errorAlert(res.message);
        }
      });
    } else {
      this.propertyService.getPropertyList(query).subscribe(res => {
        if (res.status == 1) {
          this.propertyDetails = res.data;
          console.log(this.propertyDetails);
          this.propertyDetails.property_list.forEach(obj =>{
            this.countDown(obj);
            this.realPropertySocket.connectProperty(obj.id);
          });
        } else {
          this.alertService.errorAlert(res.message);
        }
      });
    }
  }

  private countDown(obj){
    const currentDate = new Date();
    let seconds=0;
    const startDate = new Date(obj.bid_start_date);
    const endDate = new Date(obj.bid_end_date);
    endDate.setDate(endDate.getDate() + 1);
    let title = '';
    if (startDate.getTime() > currentDate.getTime()) {
      title = '<h2 class="mb-0 me-2">Bid Start: </h2>';
      seconds = Math.floor( (startDate.getTime() - currentDate.getTime()) / 1000);
    }else if (endDate.getTime() > currentDate.getTime()) {
      title='<h2 class="mb-0 me-2">Bid End : </h2>';
      seconds = Math.floor( (endDate.getTime() - currentDate.getTime()) / 1000);
    }
    let countdown = setInterval(() => {
      let days        = Math.floor(seconds/24/60/60);
      let hoursLeft   = Math.floor((seconds) - (days*86400));
      let hours       = Math.floor(hoursLeft/3600);
      let minutesLeft = Math.floor((hoursLeft) - (hours*3600));
      let minutes     = Math.floor(minutesLeft/60);
      let remainingSeconds = seconds % 60;
      function pad(n) {
        return (n < 10 ? "0" + n : n);
      }
      if (seconds == 0) {
        obj.interval='';
        clearInterval(countdown);
      } else {
        obj.interval=title;
        if(days){
          obj.interval +="<div><h3 class='mb-0'>"+pad(days)+" </h3>" + " <span class='ms-1 mb-0'> D</span></div>";
        }
        if(hours){
          obj.interval +="<div><h3 class='ms-1 mb-0'>"+pad(hours)+" </h3>" + " <span class='ms-1 mb-0'> Hrs</span></div>";
        }
        if(minutes){
          obj.interval +="<div><h3 class='ms-1 mb-0'>"+pad(minutes) +" </h3>" + " <span class='ms-1 mb-0'> Min</span></div>";
        }
        if(remainingSeconds){
          obj.interval +="<div><h3 class='ms-1 mb-0'>"+pad(remainingSeconds) +" </h3>" + " <span class='ms-1 mb-0'> Sec</span></div>";
        }
        seconds--;
      }
    }, 1000);
  }
  private realTimePropertyUpdate(){
    this.subscriptionReal=this.socketReal.propertyUpdate().subscribe(result =>{
      let check  = true;
      this.propertyDetails.property_list.forEach(obj =>{
        if(result.property_id==obj.id && check){
          this._getData(this.query);
          check=false;
        }
      });
    });
  }

  public redirectTo(property: IListDetail) {
    this.router.navigate([`/property/bid-receive-list/${property.id}`]);
  }

  deleteProperty(id: number) {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete property?', '', 'delete-info.svg');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
      panelClass: 'OverFlowCR',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.propertyService.deleteProperty(id).subscribe((res: any) => {
          if (res.status) {
            this.socketReal.propertyChange(id);
            this.alertService.successAlert(res.message);
           // this._getData(this.query);
          } else {
            this.alertService.errorAlert(res.message);
          }
        });
      }
    });
  }

  public filter(event: any) {
    this.query.sorted_by = event.target.value;
    this.query.limit = 8;
    this.query.offset = 0;
    this._getData(this.query);
  }
}

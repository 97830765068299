import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'trueYes' })
export class TrueYesPipe implements PipeTransform {
  transform(value: number) {
    if (value == 1) {
      return 'Yes';
    } else {
      return 'No';
    }
  }
}

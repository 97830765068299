<div class="propertyLisSection">
  <div class="container">
    <div *ngIf="propertyDetails?.property_list.length" class="proptyHead row align-items-center">
      <div class="col-sm-6 order-sm-1 order-2 d-flex" style="align-items: flex-end">
        <p style="font-weight: 700">Showing</p>
        <mat-paginator (page)="handlePageEvent($event)" showFirstLastButtons [length]="propertyDetails?.total_records" [pageSize]="pageSize"> </mat-paginator>
      </div>
      <div class="col-sm-6 order-sm-2 order-1 text-end">
        <div class="d-flex justify-content-end align-items-center">
          <div class="DropDwn mb-0" *ngIf="!myList">
            <select class="form-control custom-select form-select" id="sort_by" (change)="filter($event)" style="height: 46px">
              <option value="" selected>Relevance</option>
              <option value="low_to_high">Price Low to High</option>
              <option value="high_to_low">Price High to Low</option>
            </select>
          </div>
          <a *ngIf="myList && isUserLogin" routerLink="/property/add" class="SignUpBtn btn btn-theme btn-sm"
            ><span class="me-2">Add New</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 17.21 17.21">
              <path
                id="plus"
                d="M11100.583-33.979V-40h-6.023a1.294,1.294,0,0,1-1.291-1.291,1.292,1.292,0,0,1,1.291-1.291h6.023V-48.6a1.292,1.292,0,0,1,1.291-1.291,1.294,1.294,0,0,1,1.291,1.291v6.023h6.022a1.292,1.292,0,0,1,1.291,1.291,1.294,1.294,0,0,1-1.291,1.291h-6.022v6.02a1.294,1.294,0,0,1-1.291,1.294A1.292,1.292,0,0,1,11100.583-33.979Z"
                transform="translate(-11093.269 49.895)"
                fill="#fff" /></svg
          ></a>
        </div>
      </div>
    </div>
    <div class="proportySection proportySectionList">
      <div class="row">
        <ng-container *ngFor="let property of propertyDetails?.property_list; let index = index">
          <div class="col-xl-6 col-lg-12 {{ featured ? 'col-xl-featured-4' : '' }}">
            <div class="proportyItems {{ featured ? 'featured-property-items' : '' }} ">
              <div class="d-flex">
                <div class="proportyImgContent">
                  <span class="labelImgInfo"
                    ><svg xmlns="http://www.w3.org/2000/svg" width="18.879" height="17.308" viewBox="0 0 18.879 17.308">
                      <path
                        id="img"
                        d="M18734.645-962.988l-12.164-3.259a1.588,1.588,0,0,1-1.109-1.928l1.533-5.719a.391.391,0,0,1,.48-.277.392.392,0,0,1,.281.48l-1.537,5.719a.793.793,0,0,0,.559.964l12.158,3.259a.788.788,0,0,0,.959-.555l.617-2.276a.388.388,0,0,1,.479-.278.389.389,0,0,1,.277.48l-.615,2.276a1.566,1.566,0,0,1-1.514,1.166A1.716,1.716,0,0,1,18734.645-962.988Zm-8.607-4.67a1.576,1.576,0,0,1-1.576-1.576v-9.435a1.576,1.576,0,0,1,1.576-1.576h12.59a1.574,1.574,0,0,1,1.57,1.576v9.435a1.574,1.574,0,0,1-1.57,1.576Zm2.869-4.675-3.527,3.527a.789.789,0,0,0,.658.362h12.59a.79.79,0,0,0,.785-.79v-1.03l-4.021-4.694a.374.374,0,0,0-.295-.136.409.409,0,0,0-.307.141l-3.34,4.007a.371.371,0,0,1-.281.141.391.391,0,0,1-.3-.118l-1.41-1.411a.4.4,0,0,0-.279-.109A.387.387,0,0,0,18728.906-972.334Zm-3.65-6.335v8.88l3.09-3.1a1.208,1.208,0,0,1,1.67,0l1.109,1.105,3.063-3.673a1.164,1.164,0,0,1,.9-.423h.01a1.169,1.169,0,0,1,.893.409l3.424,4v-7.2a.79.79,0,0,0-.785-.79h-12.59A.787.787,0,0,0,18725.256-978.669Zm1.566,2.361a1.576,1.576,0,0,1,1.576-1.576,1.574,1.574,0,0,1,1.57,1.576,1.572,1.572,0,0,1-1.57,1.566A1.574,1.574,0,0,1,18726.822-976.308Zm.785,0a.79.79,0,0,0,.791.781.786.786,0,0,0,.785-.781.788.788,0,0,0-.785-.79A.792.792,0,0,0,18727.607-976.308Z"
                        transform="translate(-18721.318 980.244)"
                        fill="#fff" />
                    </svg>
                    <span class="ms-2"> {{ property.total_images }}</span>
                  </span>
                  <div
                    *ngIf="property.property_images.length"
                    id="image-slider-{{ index }}"
                    class="carousel slide"
                    data-ride="carousel"
                    data-interval="false"
                    data-class="image-slider">
                    <!-- Wrapper for slides -->
                    <div class="carousel-inner">
                      <ng-container *ngFor="let photo of property.property_images; let i = index">
                        <div class="image-slider {{ !i ? 'active' : '' }}">
                          <a [routerLink]="['/property/detail', property.id]" class="w-100">
                            <img src="{{ photo.images }}" alt="" />
                          </a>
                        </div>
                      </ng-container>
                    </div>

                    <!-- Left and right controls -->
                    <div class="sllider-buttons sllider-buttons-image d-flex" *ngIf="property.property_images.length > 1">
                      <a class="left carousel-control me-2" href="#image-slider-{{ index }}" data-slide="prev"
                        ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 28.926 14.011">
                          <path
                            id="arrow-left"
                            d="M27.8,8.135H3.867L7.831,12.08a1.13,1.13,0,0,1-1.594,1.6L.332,7.806h0a1.131,1.131,0,0,1,0-1.6h0L6.236.329a1.13,1.13,0,0,1,1.594,1.6L3.867,5.875H27.8a1.13,1.13,0,1,1,0,2.26Z"
                            transform="translate(0 0)"
                            fill="#fff" /></svg
                      ></a>
                      <a class="right carousel-control" href="#image-slider-{{ index }}" data-slide="next"
                        ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 28.926 14.011">
                          <path
                            id="arrow-right"
                            d="M27.8,5.875H3.867L7.831,1.931A1.13,1.13,0,0,0,6.236.329L.332,6.2h0a1.131,1.131,0,0,0,0,1.6h0l5.9,5.875a1.13,1.13,0,0,0,1.594-1.6L3.867,8.135H27.8a1.13,1.13,0,1,0,0-2.26Z"
                            transform="translate(28.926 14.011) rotate(180)"
                            fill="#fff" /></svg
                      ></a>
                    </div>
                  </div>
                  <div *ngIf="!property.property_images.length" class="default-image-slider">
                    <a [routerLink]="['/property/detail', property.id]" class="w-100">
                      <img src="../assets/images/no_property_image.png" alt="" />
                    </a>
                  </div>
                </div>
                <div class="proportyInfoContent">
                  <div class="d-flex align-items-center">
                    <a class="text-decoration-none" [routerLink]="['/property/detail', property.id]">
                      <h4 *ngIf="featured" title="{{ property.name }}">{{ property.name | excerpt : 35 }}</h4>
                      <h4 *ngIf="!featured" class="mb-0">{{ property.name }}</h4>
                    </a>
                    <ng-container *ngIf="myList && isUserLogin">
                      <div class="actionBtns ms-auto">
                        <a [routerLink]="['/property/edit', property.id]"
                          ><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19.019 19.219">
                            <path
                              id="edit"
                              d="M12070.185-179.04a2.455,2.455,0,0,1-2.451-2.454v-11.554a2.454,2.454,0,0,1,2.451-2.451h6.455a.685.685,0,0,1,.686.686.685.685,0,0,1-.686.686h-6.455a1.082,1.082,0,0,0-1.083,1.077v11.557a1.084,1.084,0,0,0,1.083,1.083h11.558a1.084,1.084,0,0,0,1.083-1.083v-6.455a.683.683,0,0,1,.682-.683.684.684,0,0,1,.686.683v6.455a2.454,2.454,0,0,1-2.45,2.454Zm4-5.581a.688.688,0,0,1-.222-.634l.686-3.367a.7.7,0,0,1,.164-.343l7.356-8.1a.682.682,0,0,1,.473-.221.629.629,0,0,1,.3.055,1.884,1.884,0,0,1,.276-.422,1.837,1.837,0,0,1,1.269-.6,1.825,1.825,0,0,1,1.319.473l.34.322a1.791,1.791,0,0,1,.6,1.508,1.8,1.8,0,0,1-.853,1.383.678.678,0,0,1-.091.783l-7.368,8.117a.658.658,0,0,1-.307.194l-3.285.995a.667.667,0,0,1-.2.032A.7.7,0,0,1,12074.183-184.621Zm1.757-3.558-.413,2.054,2.023-.607,6.758-7.45-.624-.567-.073-.064-.892-.825Zm8.28-8.56a.458.458,0,0,0-.13.328.465.465,0,0,0,.137.325l.23.209.127.112a.473.473,0,0,0,.343.115.451.451,0,0,0,.409-.3.461.461,0,0,0-.121-.5l-.343-.322a.464.464,0,0,0-.31-.118h-.023A.458.458,0,0,0,12084.22-196.739Z"
                              transform="translate(-12067.733 198.258)"
                              fill="#fff" /></svg
                        ></a>
                        <a (click)="deleteProperty(property.id)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18.526 21.078">
                            <path
                              id="delete"
                              d="M12000.976-175.788a1.863,1.863,0,0,1-1.846-1.7l-1.1-13.3a1.851,1.851,0,0,1-1.278-1.761,1.856,1.856,0,0,1,1.854-1.851h3.087v-.618a1.854,1.854,0,0,1,1.852-1.851h4.939a1.856,1.856,0,0,1,1.854,1.851v.618h3.088a1.856,1.856,0,0,1,1.852,1.851,1.854,1.854,0,0,1-1.275,1.761l-1.1,13.3a1.864,1.864,0,0,1-1.846,1.7Zm-.615-1.8a.62.62,0,0,0,.615.564h10.077a.618.618,0,0,0,.615-.564l1.086-13.1h-13.479Zm-2.373-14.957a.619.619,0,0,0,.618.618h14.82a.616.616,0,0,0,.615-.618.616.616,0,0,0-.615-.615h-14.82A.618.618,0,0,0,11997.987-192.545Zm4.938-2.47v.618h6.176v-.618a.616.616,0,0,0-.618-.615h-4.939A.616.616,0,0,0,12002.926-195.014Zm2.47,16.139v-9.963a.619.619,0,0,1,.618-.618.619.619,0,0,1,.618.618v9.963a.619.619,0,0,1-.618.618A.619.619,0,0,1,12005.4-178.876Zm-3.085.039-.618-9.963a.618.618,0,0,1,.579-.654.619.619,0,0,1,.654.576l.618,9.963a.62.62,0,0,1-.618.657A.618.618,0,0,1,12002.311-178.837Zm6.752.576a.618.618,0,0,1-.576-.654l.618-9.96a.616.616,0,0,1,.654-.579.617.617,0,0,1,.576.654l-.618,9.963a.615.615,0,0,1-.615.577Z"
                              transform="translate(-11996.751 196.866)"
                              fill="#fff" />
                          </svg>
                        </a>
                      </div>
                    </ng-container>
                  </div>
                  <div class="d-flex align-items-center">
                    <p class="mt-2">
                      <span class="labelDeT">{{ property.type }}</span>
                    </p>
                    <div class="maxBid ms-auto" *ngIf="property.max_bid_price">
                      Highest bid : <b>£{{property.max_bid_price}}</b>
                    </div>
                  </div>
                  <p *ngIf="!featured">{{ property.description | excerpt : 150 }}</p>
                  <p *ngIf="featured" class="mb-0"><img src="../assets/images/location.svg" alt="icon" width="12" class="me-2" /> {{ property.location }}</p>
                  <p *ngIf="featured">
                    <!-- <ng-template #days_remaining>
                      Days Remaining : <img src="../assets/images/time.svg" alt="icon"> {{ property.days_remaining }}
                    </ng-template> -->
                  </p>
                  <div class="aminityIcons d-flex align-items-center" *ngIf="!featured">
                    <div class="amini">
                      <div class="d-flex">
                        <img src="../assets/images/bedroom.svg" alt="" />
                        <b class="ms-auto">{{ property.number_of_beds }}</b>
                      </div>
                      <p class="mt-1 mb-0">Bedrooms</p>
                    </div>
                    <div class="amini">
                      <div class="d-flex">
                        <img src="../assets/images/bathrooms.svg" alt="" />
                        <b class="ms-auto">{{ property.number_of_bathrooms }}</b>
                      </div>
                      <p class="mt-1 mb-0">Bathrooms</p>
                    </div>

                  </div>
                  <!-- <div class="aminityContent d-flex align-items-center pt-3">
                    <div  [innerHtml]="property.interval" class="countdown countdownCUstom"></div>
                  </div> -->
                  <br>
                  <div class="row">
                    <div class="col-md-6 maxBid" *ngIf="property.bid_start_price">
                      Bid start price : <b>£{{property.bid_start_price}}</b>
                    </div>
                    <div class="col-md-6 maxBid" *ngIf="property.bid_inc_percentage">
                      Bid increment : <b>{{property.bid_inc_percentage}}%</b>
                    </div>
                  </div>
                  <div class="aminityContent d-flex align-items-center pt-1">
                    <div class="priceCont ">
                      <b>£{{ property.rent_price }}</b><small>/{{ property.price_type }}</small>
                    </div>

                    <div class="timeRemainingCont ms-auto" *ngIf="featured">
                      <ng-container *ngIf="property.days_remaining; then days_remaining; else date"></ng-container>
                      <ng-template #days_remaining>
                        <div class="text-center timeRemaining">
                          <img src="../assets/images/time.svg" alt="icon" width="17" /> <span>{{ property.days_remaining }}</span>
                          <p class="mb-0">Days Remaining</p>
                        </div>
                      </ng-template>
                      <ng-template #date>
                        <div class="text-center timeRemaining">
                          <img src="../assets/images/date-icon.svg" alt="icon" width="17" />
                          <p class="mb-0 pt-1">Date :{{ property.available_date | date : 'dd-MM-yyyy' }}</p>
                        </div>
                      </ng-template>
                    </div>

                    <ng-container *ngIf="myList && isUserLogin">
                      <div class="ms-auto" (click)="redirectTo(property)">
                        <a *ngIf="property.max_bid_price && isUserLogin" class="SignUpBtn btn btn-theme ms-3" style="padding:5px 18px"><span>View Bids</span></a>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>


                <div  [innerHtml]="property.interval" class="countdown countdownCUstom"></div>


            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="propertyDetails?.property_list && !propertyDetails?.property_list.length">
      <div class="ms-auto col-sm-6 order-sm-2 order-1 text-end">
        <a *ngIf="myList && isUserLogin" routerLink="/property/add" class="SignUpBtn btn btn-theme mb-2"
          ><span class="me-2">Add New</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 17.21 17.21">
            <path
              id="plus"
              d="M11100.583-33.979V-40h-6.023a1.294,1.294,0,0,1-1.291-1.291,1.292,1.292,0,0,1,1.291-1.291h6.023V-48.6a1.292,1.292,0,0,1,1.291-1.291,1.294,1.294,0,0,1,1.291,1.291v6.023h6.022a1.292,1.292,0,0,1,1.291,1.291,1.294,1.294,0,0,1-1.291,1.291h-6.022v6.02a1.294,1.294,0,0,1-1.291,1.294A1.292,1.292,0,0,1,11100.583-33.979Z"
              transform="translate(-11093.269 49.895)"
              fill="#fff" />
          </svg>
        </a>
      </div>
      <div style="display: flex; justify-content: center">
        <img src="../assets/images/no_property_found.png" style="width:250px" alt="img" />
      </div>
    </div>
  </div>
</div>

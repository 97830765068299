export const loggedInMenu = [
  { link: '/my-interests', menu : 'Saved Properties'},
  { link: '/property/list', menu : 'My listing' },
  { link: '/property/my-bids', menu : 'My Bids'},
  { link: '/chat', menu : 'Chat'}
]

export const adminLoggedInMenu = [
  { link: '/admin/dashboard', menu : 'Dashboard' },
  { link: '/admin/user', menu : 'User Management' },
  { link: '/admin/bid-management', menu : 'Bid Management' },
  { link: '/admin/property', menu : 'Property listing' }
]

export interface IsideBarMenu {
  link: string;
  menu : string;
  id:string;
  image:string;
  actionName?: () => void;
}


export const loggedOutMenu = [
  //{ link: '', menu : 'Subscription Plan'}
]

// export const sidebarMenu : IsideBarMenu[] = [
//   { link: '/profile', menu : 'Edit Profile',id:"edit_profile",image:"edit.svg",action:false},
//   { link: '/my-interests', menu : 'My Interest',id:"my_interests",image:"interests.svg",action:false },
//   { link: '/my-subscription', menu : 'Subscription',id:"my_subscription",image:"subscription.svg",action:false},
//   { link: '/change-password', menu : 'Change Password',id:"change_password",image:"change-password.svg",action:false},
//   { link: '/delete-account', menu : 'Delete Account',id:"delete_account",image:"delete-account.svg",action:true},
//   { link: '', menu : 'Logout',id:'logout',image:"Logout.svg",action:true,actionName:logOut}
// ]
//
// export const sidebarAdminMenu :IsideBarMenu[] = [
//   { link: '/admin/profile', menu : 'Edit Profile', id:"edit_profile",image:"edit.svg",action:false},
//   { link: '/', menu : 'Property',id:"property",image:"edit.svg" ,action:false},
//   { link: '/', menu : 'User',id:"user",image:"edit.svg" ,action:false},
//   { link: '/admin/subscription', menu : 'Subscription',id:"subscription",image:"subscription.svg",action:false},
//   { link: '/admin/change-password', menu : 'Change Password',id:"change_password",image:"change-password.svg",action:false},
//   { link: '/', menu : 'Logout',id:"logout",image:"Logout.svg",action:true}
// ]

export const headerProfileMenu = [
  { link: '/my-interests', menu : 'Saved Properties',id:"my_interests",image:"interests.svg" },
  //{ link: './change-password', menu : 'Change Password',id:"change_password",image:"change-password.svg"},
  // { link: '', menu : 'Logout',id:'logout',image:"Logout.svg",actionName:logOut}
]

export const adminHeaderProfileMenu = [
  //{ link: '/change-password', menu : 'Change Password',id:"change_password",image:"change-password.svg"},
  // { link: '', menu : 'Logout',id:'logout',image:"Logout.svg",actionName:logOut}
]



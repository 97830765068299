import {Component, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent{
  title:string;
  message:string;
  image:string;
  constructor(
    private dialogRef:MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:ConfirmDialogModel
  ) {
      this.title=data.title;
      this.message=data.message;
      this.image=data.image;
  }
  public onConfirm(){
    this.dialogRef.close(true);
  }
  public onCancel(){
    this.dialogRef.close(false);
  }
}
export class ConfirmDialogModel{
  constructor(public title:string,public message:string,public image:string) {
  }
}

export const API_END_POINT = {
  auth: {
    signup: 'auth/signup',
    login: 'auth/login',
    logout: 'auth/logout',
    forgot_password: 'auth/forgot_password',
    reset_password: 'auth/reset_password',
  },
  profile: {
    update: 'update-profile',
    change_password: 'change-password',
    update_setting:'edit-settings'
  },
  user: {
    delete: 'user/delete',
  },
  general: {
    getSettings: 'get-settings',
    cityList: 'city/list',
  },
  property: {
    save: 'property/add_edit',
    list: 'property/list',
    my_list: 'user_property/list',
    delete: 'property/delete',
    details: 'property/details',
    update_interest: 'property/update_interest',
    my_interest_list: 'property/my_interest_list',
    delete_image: 'property/delete_image',
  },
  contact: {
    add: 'contact/add',
  },
  admin: {
    property: 'admin/property_list',
    user: 'user/list',
    userManagementList: 'admin/user-list',
    userUpdateStatus: 'admin/user/update-status',
    get_user_details: 'admin/user/get-details',
    update_user_details: 'admin/user/update-details',
    deleteUser: 'admin/user/delete',
    update_featured_property: 'admin/update_featured_property',
    dashboard: 'admin/dashboard',
  },
  bid: {
    submit: 'bid/add_edit',
    userBidList: 'bid/user-bid-list',
    delete: 'bid/delete',
    receivedBid: 'bid/received-list',
    acceptBid: 'bid/purchase',
    rejectBid: 'bid/reject',
    adminBidList: 'admin/bidlist',
  },
  chat: {
    list: 'chat/conversation/list',
    detail: 'chat/message-list',
    sendMessage: 'chat/send',
    reportMessage: 'chat/message-report',
    readConversationMessages: 'chat/mark-read',
  },
  transaction:{
    list:'transaction-list'
  }
};

<section class="login-secction">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-6 loginLeftcontent text-center d-flex align-items-center" style="background-image: url('../assets/images/login-left-img.jpg');">
        <div class="loginContentvrCenter">
          <h3>Welcome Back!</h3>
          <!-- <p>Contrary to popular belief,  Lorem Ipsum There are many variation</p> -->
          <br>
          <a routerLink="/auth/login" class="btn btn-theme w-100">Sign In</a>
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center">
        <div class="loginRightcontent w-100">
          <h1 class="loginTitle text-center">Reset Password!</h1> <br>
          <!-- <h4>Create An Account</h4> -->

          <form class="form-horizontal mt-4" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
            <div class="col-12">
              <div class="form-floating form-group">
                <input type="password" class="form-control" id="password1" placeholder="Password" formControlName="password">
                <label for="password1">New Password <span class="require">*</span></label>
                <div *ngIf="f['password'].touched && f['password'].hasError('required')" class="alert alert-danger">
                  Password is required.
                </div>
                <div *ngIf="f['password'].touched && f['password'].hasError('maxlength')" class="alert alert-danger">
                  More than 100 characters are not allowed.
                </div>
                <div *ngIf="f['password'].touched && f['password'].hasError('minlength')" class="alert alert-danger">
                  Min 8 characters are required.
                </div>
                <span class="EyeShowHide" onclick="if($('#password1').attr('type') == 'password'){$('#password1').attr('type','text');}else{$('#password1').attr('type','password');}$('.eye-show').toggle();$('.eye-hide').toggle()">
                  <img src="./assets/images/eye-hide.svg" class="eye-show">
                  <img src="./assets/images/eye-show.svg"  class="eye-hide" style="display: none">
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating form-group">
                <input type="password" class="form-control" id="confirm_password1" placeholder="Confirm Password" formControlName="confirm_password">
                <label for="confirm_password1">Re-enter New Password <span class="require">*</span></label>
                <div *ngIf="f['confirm_password'].touched && f['confirm_password'].hasError('required')" class="alert alert-danger">
                  Confirm Password is required.
                </div>
                <div *ngIf="passwordMatchError && !f['confirm_password'].hasError('required')" class="alert alert-danger">
                  Password & Confirm Password does not match.
                </div>
                <div *ngIf="f['confirm_password'].touched && f['confirm_password'].hasError('maxlength')" class="alert alert-danger">
                  More than 100 characters are not allowed.
                </div>
                <div *ngIf="f['confirm_password'].touched && f['confirm_password'].hasError('minlength')" class="alert alert-danger">
                  Min 8 characters are required.
                </div>
                <span class="EyeShowHide" onclick="if($('#confirm_password1').attr('type') == 'password'){$('#confirm_password1').attr('type','text');}else{$('#confirm_password1').attr('type','password');}$('.eye-show-confirm_password').toggle();$('.eye-hide-confirm_password').toggle()">
                  <img src="./assets/images/eye-hide.svg" class="eye-show-confirm_password">
                  <img src="./assets/images/eye-show.svg"  class="eye-hide-confirm_password" style="display: none">
                </span>
              </div>
            </div>

            <div class="text-center pt-3">
              <button class="btn btn-theme w-100" type="submit" [disabled]="!resetPasswordForm.valid">Submit</button>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
</section>

import { Injectable } from '@angular/core';
import { ApiHttpService } from "../../shared/service/api-http.service";
import  {Observable,throwError} from "rxjs";
import {API_END_POINT} from "../../shared/configs/api.config";
import {IResponseDto} from "../../shared/models/api-response";
import {IList} from "../../shared/models/property/list";
import {ICity} from "../../shared/models/common/cityList";

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(
    private apiHttpService:ApiHttpService
  ) { }
  public addContact(contact:any):Observable<any>{
    return this.apiHttpService.post(API_END_POINT.contact.add,contact)
  }
  public getFeaturedProperties(query: any):Observable<IResponseDto<IList>>{
    // console.log(query)
    query = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');
    return this.apiHttpService.get(API_END_POINT.property.list+'?'+query)
  }
  public getCities():Observable<IResponseDto<ICity[]>>{
    return this.apiHttpService.get(API_END_POINT.general.cityList)
  }
}

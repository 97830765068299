
<section class="login-secction">
    <div class="container">
        <div class="row h-100">
            <div class="col-lg-6 loginLeftcontent text-center d-flex align-items-center" style="background-image: url('../assets/images/login-left-img.jpg');">
                <div class="loginContentvrCenter">
                    <h3>Let’s Get Started</h3>
                    <!-- <p>Contrary to popular belief,  Lorem Ipsum There are many variation</p> -->
                    <br>
                    <a routerLink="/auth/register" class="btn btn-theme w-100">Sign Up</a>
                </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center">
                <div class="loginRightcontent w-100">
                    <h1 class="loginTitle text-center">
                      <a routerLink="/"><img src="../assets/images/logo-black.svg" style="width: 40%;"></a>
                    </h1>
                    <!-- <h4>Create An Account</h4> -->

                    <form class="form-horizontal mt-4" [formGroup]="loginForm" (ngSubmit)="signIn()">
                        <div class="form-floating form-group">
                          <input type="email" class="form-control" id="email" placeholder="name@example.com" formControlName="email">
                          <label for="email">Email address <span class="require">*</span></label>
                          <div *ngIf="f['email'].touched && !f['email'].hasError('email') && f['email'].hasError('required')" class="alert alert-danger">
                            Email Address is required.
                          </div>
                          <div *ngIf="f['email'].hasError('email')  && !f['email'].hasError('required')" class="alert alert-danger">
                            Please enter a valid Email Address.
                          </div>
                        </div>
                        <div class="form-floating form-group position-relative">
                            <input type="password" class="form-control" id="pass" placeholder="Password" formControlName="password">
                          <label for="pass">Password <span class="require">*</span></label>
                          <div *ngIf="f['password'].touched && f['password'].hasError('required')" class="alert alert-danger">
                            Password is required.
                          </div>
                          <div *ngIf="f['password'].touched && f['password'].hasError('maxlength')" class="alert alert-danger">
                            More than 100 characters not allowed.
                          </div>
                          <div *ngIf="f['password'].touched && f['password'].hasError('minlength')" class="alert alert-danger">
                            Min 8 characters required.
                          </div>
                          <span class="EyeShowHide" onclick="if($('#pass').attr('type') == 'password'){$('#pass').attr('type','text');}else{$('#pass').attr('type','password');}$('.eye-show-password').toggle();$('.eye-hide-password').toggle()">
                            <img src="./assets/images/eye-hide.svg" class="eye-show-password">
                            <img src="./assets/images/eye-show.svg"  class="eye-hide-password" style="display: none">
                          </span>
                        </div>

                        <div class="mb-3 row mt-4">
                            <div class="col-6">
<!--                                <div class="form-check">-->
<!--                                    <input type="checkbox" class="form-check-input" id="customControlInline">-->
<!--                                    <label class="form-check-label" for="customControlInline">Remember me-->
<!--                                    </label>-->
<!--                                </div>-->
                            </div>
                            <div class="col-6 text-end">
                                <a routerLink="/auth/forgot-password" class="textForgot"> Forgot Password?</a>
                            </div>
                        </div>

                        <div class="text-center pt-3">
                          <button class="btn btn-theme w-100" type="submit" [disabled]="!loginForm.valid">Sign In</button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
</section>

export interface ISetting {
  property_type:ISettingDetail[],
  number_of_beds:ISettingDetail[],
  number_of_bathrooms:ISettingDetail[],
  property_amenities:ISettingDetail[],
  property_price:ISettingDetail[],
  property_photos_limit:ISettingDetail[],
  property_documents_limit:ISettingDetail[],
}

export interface ISettingDetail {
  value: string;
}

export class Setting implements ISetting {
  number_of_bathrooms: ISettingDetail[];
  number_of_beds: ISettingDetail[];
  property_amenities: ISettingDetail[];
  property_photos_limit: ISettingDetail[];
  property_documents_limit: ISettingDetail[];
  property_price: ISettingDetail[];
  property_type: ISettingDetail[];

}

<div class="dilogHeaderDelete position-relative">
  <div class="crossIcon text-center">
    <img src="../../../../assets/images/info-bids.svg" />
  </div>
</div>

<div class="containerDiv">
  <h1 mat-dialog-title class="text-center DeleteTitle">Are you sure ?</h1>

  <div mat-dialog-content class="form-group">
    <p class="text-center peraContent">Are you sure you want to Reject this bid</p>
    <textarea class="form-control" [formControl]="reasonForReject"></textarea>
  </div>

  <div mat-dialog-actions class="justify-content-center d-flex p-3 btnGrp">
    <button mat-button (click)="onCancel()" class="cancel w-100 btn btn-outline btn-sm m-2">Cancel</button>
    <button mat-raised-button (click)="onConfirm()" class="w-100 btn btn-theme btn-sm m-2">Confirm</button>
  </div>
</div>

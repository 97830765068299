import { Component } from '@angular/core';
import { LoaderService } from '../../service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  loading: boolean;
  value: number = 10;
  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe(v => {
      this.loading = v;
    });
    this.loaderService.value.subscribe((v: any) => {
      this.value = v;
    });
  }
}

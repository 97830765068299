import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SidebarLoginComponent } from "./sidebar-login/sidebar-login.component";

const routes: Routes = [
  {
    path:'login',
    component:LoginComponent,
    title: 'Login',
  },
  {
    path:'register',
    component:RegisterComponent,
    title: 'Register',
  },
  {
    path:'forgot-password',
    component:ForgotPasswordComponent,
    title: 'Forgot Password',
  },
  {
    path:'reset-password/:token',
    component:ResetPasswordComponent,
    title: 'Reset Password',
  },
  {
    path:'sidebar-login',
    component:SidebarLoginComponent,
    title: 'Sidebar Login',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }

import { Injectable } from '@angular/core';
import { ApiHttpService } from '../../shared/service/api-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_END_POINT } from '../../shared/configs/api.config';
import { IResponseDto } from '../../shared/models/api-response';
import { ISetting } from '../../shared/models/property/setting';
import { IList } from '../../shared/models/property/list';
import { IQuerySpecsDto, QuerySpecs } from '../../shared/models/common/query.model';
import { IBidList } from '../../shared/models/bid/list';
import { IBidReceiveDto } from 'src/app/shared/models/property/bidReceive.model';
import { RealtimeSocketService } from "../../shared/service/realtime-socket.service";

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  query$: BehaviorSubject<IQuerySpecsDto> = new BehaviorSubject<IQuerySpecsDto>(new QuerySpecs());
  constructor(private apiHttpService: ApiHttpService,private socket: RealtimeSocketService) {}

  public getPropertySetting(type:string): Observable<IResponseDto<ISetting>> {
    return this.apiHttpService.get(API_END_POINT.general.getSettings + '?type='+type);
  }
  public saveProperty(property: any): Observable<IResponseDto<any>> {
    return this.apiHttpService.post(API_END_POINT.property.save, property);
  }
  public getMyPropertyList(query: any): Observable<IResponseDto<IList>> {
    query = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');
    return this.apiHttpService.get(API_END_POINT.property.my_list + '?' + query);
  }
  public getPropertyList(query: any): Observable<IResponseDto<IList>> {
    query = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');
    return this.apiHttpService.get(API_END_POINT.property.list + '?' + query);
  }
  public deleteProperty(id: number): Observable<IResponseDto<any>> {
    return this.apiHttpService.delete(API_END_POINT.property.delete + '?property_id=' + id);
  }
  public getProperty(id: number, user_id: number): Observable<IResponseDto<any>> {
    this.socket.connectProperty(id);
    return this.apiHttpService.get(API_END_POINT.property.details + '?property_id=' + id + '&user_id=' + user_id);
  }
  public addToFavourite(id: number, is_interest: number): Observable<IResponseDto<any>> {
    return this.apiHttpService.post(API_END_POINT.property.update_interest, { property_id: id, is_interest: is_interest });
  }
  public deletePropertyImage(id: number): Observable<IResponseDto<any>> {
    return this.apiHttpService.delete(API_END_POINT.property.delete_image + '?image_id=' + id);
  }
  public submitBid(bid: any): Observable<IResponseDto<any>> {
    return this.apiHttpService.post(API_END_POINT.bid.submit, bid);
  }
  public getMybids(query: any): Observable<IResponseDto<IBidList>> {
    query = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');
    return this.apiHttpService.get(API_END_POINT.bid.userBidList + '?' + query);
  }
  public deleteBid(id: number): Observable<IResponseDto<any>> {
    return this.apiHttpService.delete(API_END_POINT.bid.delete + '?bid_id=' + id);
  }

  public getReceiveBidList(query: any): Observable<IResponseDto<IBidReceiveDto>> {
    query = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');
    return this.apiHttpService.get(API_END_POINT.bid.receivedBid + '?' + query);
  }

  public acceptBid(data: any):  Observable<IResponseDto<any>> {
    return this.apiHttpService.post(API_END_POINT.bid.acceptBid, data);
  }

  public rejectBid(data: any): Observable<IResponseDto<any>> {
    return this.apiHttpService.post(API_END_POINT.bid.rejectBid, data);
  }
  public getAdminBidList(query: any): Observable<IResponseDto<any>> {
    query = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');
    return this.apiHttpService.get(API_END_POINT.bid.adminBidList + '?' + query);
  }
}

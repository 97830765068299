<section class="login-secction">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-6 loginLeftcontent text-center d-flex align-items-center" style="background-image: url('../assets/images/login-left-img.jpg');">
        <div class="loginContentvrCenter">
          <h3>Welcome Back!</h3>
          <!-- <p>Contrary to popular belief,  Lorem Ipsum There are many variation</p> -->
          <br>
          <a routerLink="/auth/login" class="btn btn-theme w-100">Sign In</a>
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center">
        <div class="loginRightcontent w-100">
          <h1 class="loginTitle text-center">Forgot Password!</h1> <br>
          <!-- <h4>Create An Account</h4> -->

          <form class="form-horizontal mt-4" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
            <div class="form-floating form-group">
              <input type="email" class="form-control" id="email" placeholder="name@example.com" formControlName="email">
              <label for="email">Email address <span class="require">*</span></label>
              <div *ngIf="f['email'].touched && !f['email'].hasError('email') && f['email'].hasError('required')" class="alert alert-danger">
                Email Address is required.
              </div>
              <div *ngIf="f['email'].hasError('email')  && !f['email'].hasError('required')" class="alert alert-danger">
                Please enter a valid Email Address.
              </div>
            </div>
            <div class="text-center pt-3">
              <button class="btn btn-theme w-100" type="submit" [disabled]="!forgotPasswordForm.valid">Submit</button>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
</section>

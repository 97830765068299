import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import {AlertService} from "../../../shared/service/alert/alert.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  //#region public variables
  forgotPasswordForm: FormGroup;
  //#endregion public variables

  constructor(
    private authService: AuthService,
    private router: Router,
    private alert:AlertService
  ) { }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  public forgotPassword() {
    if (this.forgotPasswordForm.valid) {
      let data = {
        email: this.f['email'].value
      };
      this.authService.forgotPassword(data).subscribe((res: any) => {
        if(res.status){
          this.alert.successAlert(res.message);
          this.router.navigate(['/auth/login']);
        }else{
          this.alert.errorAlert(res.message);
        }
      })
    }
  }

}

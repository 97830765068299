import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";
import {AlertService} from "../../../shared/service/alert/alert.service";
import {CustomValidators} from "../../../shared/classes/CustomValidatorsClass";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  token:string;
  constructor(private authService: AuthService,
              private router: Router,
              private alert:AlertService,
              private _fb: FormBuilder) {
    this.token = this.router.url?.split('/')[3];
    this.resetPasswordForm = this._initForm(this.token)
  }

  get f() {
    return this.resetPasswordForm.controls;
  }
  get passwordMatchError() {
    return (
      this.resetPasswordForm.getError('mismatch') &&
      this.resetPasswordForm.get('confirm_password')?.touched
    );
  }

  ngOnInit(): void {

  }

  public resetPassword(){
    if (this.resetPasswordForm.valid) {
      this.authService.resetPassword(this.resetPasswordForm.getRawValue()).subscribe((res: any) => {
        if(res.status){
          this.alert.successAlert(res.message);
          this.router.navigate(['auth/login']);
        }else{
          this.alert.errorAlert(res.message);
        }
      })
    }
  }

  private _initForm(token: string): FormGroup {
    let fg = this._fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      confirm_password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]],
      token : [token, [Validators.required]]
      },
      {
        validator: CustomValidators.MatchValidator('password', 'confirm_password')
      }
    )
    return fg;
  }

}

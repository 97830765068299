import { Component, OnInit,AfterViewInit, ViewChild,ElementRef,NgZone  } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
import {adminLoggedInMenu, IsideBarMenu, loggedInMenu, loggedOutMenu} from '../../configs/menu.configs';
import { LocalStoreService } from '../../service/local-store.service';
import { ReusableClass } from '../../classes/ReusableClass';
import { filter, Observable, of, switchMap, throwError } from 'rxjs';
import { ISetting, Setting } from '../../models/property/setting';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../classes/CustomValidatorsClass';
import { PropertyService } from '../../../components/property/property.service';
import { imagePath } from '../../configs/imagePath.config';
import { MapService,Maps } from "../../service/map.service";
import {IQuerySpecsDto, QuerySpecs} from "../../models/common/query.model";
import {RoutePartsService} from "../../service/routeParts.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ReusableClass,DatePipe],
})
export class HeaderComponent implements OnInit {
  @ViewChild('search')
  public searchElementRef: ElementRef;
  /**
   * set Public variables
   * */
  miles: number[] = [];
  propertySearch: FormGroup;
  propertySetting: ISetting = new Setting();
  isLoggedIn = false;
  user;
  menuArray: { link: string; menu: string }[] = [];
  activeRouter: string;
  headerProfileMenuArray: IsideBarMenu[] = [];
  name = '';
  toogleMenu: boolean = false;
  toogleProfile: boolean = false;
  classToggled = false;
  isFilter;
  isAdmin=false;
  query: IQuerySpecsDto= new QuerySpecs();
  displayAvailableDate: boolean = false
  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private _activeRoute: ActivatedRoute,
    private ls: LocalStoreService,
    private reusable: ReusableClass,
    private router: Router,
    private propertyService: PropertyService,
    private mapService: MapService,
    private ngZone: NgZone,
    private routePartsService: RoutePartsService,
    private datePipe: DatePipe
  ) {
    for (let i = 1; i <= 5; i++) {
      this.miles.push(i);
    }
    /**
     * set default value for page load and is filter data api dropdown data
     */
    let data = this._activeRoute.snapshot.data;
    this.name = this._getTitle();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((res: any) => {
      this.toogleProfile=false;
      this.name = this._getTitle();
      this.isFilter = this._filterPage();
      if (this.isFilter) {
        this._productSettings();
        this.propertyService.query$.subscribe(res => {
          this.propertySearch = this._initForm(res);
        });
      }
    });
  }

  /**
   * set title and filter data set on on init
   */
  ngOnInit(): void {
    this.toogleProfile=false;
    this.activeRouter=this.router.url;
    this.isFilter = this._filterPage();
    if (this.isFilter) {
      this._productSettings();
      this.propertyService.query$.subscribe(res => {
        console.log(res)
        this.propertySearch = this._initForm(res);
      });
    }
    this._pageConfigs();
  }
  ngAfterViewInit(): void {
      if (this.searchElementRef != undefined) {
        this.mapService.api.then(maps => {
          this.initAutocomplete(maps);
        });
      }
  }
  initAutocomplete(maps: Maps) {
    const options = {
      componentRestrictions: { country: "uk" },
      strictBounds: true,
      types: ['address']
    };
    let autocomplete = new maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      options
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // const place=autocomplete.getPlace();
        let mainKey = Object.keys(autocomplete['gm_accessors_'].place)[0];
        const place = autocomplete.getPlace();
        this.f['lng'].patchValue(place.geometry.location.lng());
        this.f['lat'].patchValue(place.geometry.location.lat());
        // this.f['location'].patchValue(autocomplete.gm_accessors_.place.Kj.formattedPrediction);
        this.f['location'].patchValue(autocomplete['gm_accessors_'].place[mainKey].formattedPrediction);
      });
    });
  }
  /**
   * toogle button on fo profile menu
   */
  public toggleField() {
    this.classToggled = !this.classToggled;
  }

  /**
   * default page config for particular page
   * @private
   */
  private _pageConfigs() {
    this.activeRouter = this.router.url;

    const logout = () => {
      this.reusable.logout();
    };
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.user = this.ls.getItem('User');
      this.authService.user$.subscribe(user => {
        if (user.user_id) {
          this.user = user;
          this.user.profile_picture = this.user.profile_picture ? this.user.profile_picture : imagePath.defaultProfile;
        } else {
          this.user = this.ls.getItem('User');
          this.user.profile_picture = this.user.profile_picture ? this.user.profile_picture : imagePath.defaultProfile;
        }
      });
      if (!this.authService.isAdmin()) {
        this.menuArray = loggedInMenu;
        this.headerProfileMenuArray = [
          { link: '/my-interests', menu: 'Saved Properties', id: 'my_interests', image: 'interests.svg' },
        //  { link: './change-password', menu: 'Change Password', id: 'change_password', image: 'change-password.svg' },
          { link: '', menu: 'Logout', id: 'logout', image: 'Logout.svg', actionName: logout },
        ];
      } else {
        this.isAdmin=true;
        this.menuArray = adminLoggedInMenu;
        this.headerProfileMenuArray = [
          // {
          //   link: '/admin/change-password',
          //   menu: 'Change Password',
          //   id: 'change_password',
          //   image: 'change-password.svg',
          // },
          { link: '', menu: 'Logout', id: 'logout', image: 'Logout.svg', actionName: logout },
        ];
      }
    } else {
      this.menuArray = loggedOutMenu;
    }
  }

  /**
   * set page title
   * @private
   */
  private _getTitle(): string {
    //@ts-ignore
    if (this._activeRoute.root.firstChild.snapshot.data['name']) {
      return this._activeRoute.root.firstChild.snapshot.data['name'];
    } else {
      if(this.router.url == '/admin/property'){
          return 'Property Listing';
      }else if(this.router.url == '/admin/profile'){
        return 'Profile';
      }else if(this.router.url == '/admin/change-password'){
        return 'Change Password';
      }else if(this.router.url == '/admin/user'){
        return 'User Management';
      }else if(this.router.url == '/admin/dashboard'){
        return 'Dashboard';
      }else if(this.router.url == '/admin/setting'){
        return 'Settings';
      }else if(this.router.url == '/admin/transactions'){
        return 'Transactions';
      }else if(this.router.url == '/admin/bid-management'){
        return 'Bid Management';
      }else if(this.router.url == '/chat'){
        return 'Chat';
      }
      return this._activeRoute.root.children[0].snapshot.children[0]['data']['name'];
    }
  }

  /**
   * set page is filter page or not
   * @private
   */
  private _filterPage(): string {
    if (this._activeRoute.root.firstChild.snapshot.data['filter']) {
      return this._activeRoute.root.firstChild.snapshot.data['filter'];
    } else {
      return this._activeRoute.root.children[0].snapshot.children[0]['data']['filter'];
    }
  }

  /**
   * filter page form setup
   * @private
   */
  private _initForm(value?: IQuerySpecsDto): FormGroup {
    let fg = this._fb.group({
      property_type: new FormControl('', []),
      number_of_beds: new FormControl('', []),
      price_type: new FormControl('', []),
      available_date: new FormControl('', []),
      location: new FormControl('', []),
      radius: new FormControl('', []),
      lng: new FormControl(null, []),
      lat: new FormControl(null, []),
    });
    this.displayAvailableDate = false;

    fg.patchValue(value);
    console.log(fg.value)
    return fg;
  }

  /**
   *
   * @private
   * filter form data get in dropdown
   */
  private _productSettings() {
    this.propertyService.getPropertySetting('property').subscribe((res: any) => {
      if (res.status) {
        this.propertySetting = res.data;
      }
    });
  }

  /**
   * create a get method for form
   */
  get f() {
    return this.propertySearch.controls;
  }

  /**
   * filter page form submit
   */
  public propertySearchSubmit() {
    this.query = new QuerySpecs();
    this.query.property_type =this.f['property_type'].value;
    this.query.number_of_beds =this.f['number_of_beds'].value;
    this.query.price_type =this.f['price_type'].value;
    this.query.available_date =this.f['available_date'].value;
    this.query.location =this.f['location'].value;
    this.query.radius = this.f['radius'].value;
    this.query.lng =this.f['lng'].value;
    this.query.lat =this.f['lat'].value;
    console.log(this.query);
    this.propertyService.query$.next(this.query);
  }
}

<app-loader></app-loader>
<app-sidebar-login style="display: none"></app-sidebar-login>
<app-header-home *ngIf="header === true"></app-header-home>
<app-header *ngIf="header === false"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="footer"></app-footer>



<div id="myModal" class="modal messageDisplay" *ngIf="isMessage">
  <div class="modal-content">
    <div class="messageSend d-flex"><div>New message from {{messageFrom}} </div><span (click)="hideNotification()" class="close ms-auto">&times;</span></div>
    <div (click)="chatOpen()">
      <p *ngIf="messageType==0" class="messageDis">{{message}}</p>
      <div *ngIf="messageType==1"  class="imgandDuc">
        <img *ngIf="isImage(message)" src="{{message}}">
        <p *ngIf="!isImage(message)" class="messageDis">{{  message?.split('/')[4] }}</p>
      </div>
    </div>
  </div>
</div>


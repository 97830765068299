import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './shared/guards/admin.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { LogoutGuard } from './shared/guards/logout.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/components/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'auth',
    data: { hideAll: true },
    title: 'Auth',
    canActivate: [LogoutGuard],

    loadChildren: () => import('../app/components/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    data: { header: false },
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/components/profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'transactions',
    data: { header: false },
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/components/transaction/transaction.module').then(m => m.TransactionModule),
  },
  {
    path: 'property',
    data: { header: false, role: 0 },
    loadChildren: () => import('../app/components/property/property.module').then(m => m.PropertyModule),
  },
  {
    path: 'chat',
    data: { header: false, role: 0 },
    loadChildren: () => import('../app/components/chat/chat.module').then(m => m.ChatModule),
  },
  {
    path: 'admin',
    data: { header: false, role: 1 },
    canActivate: [AdminGuard],
    loadChildren: () => import('../app/components/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'pages',
    data: { header: false},
    loadChildren: () => import('../app/components/pages/pages.module').then(m => m.PagesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<header class="navigation headerCustom position-absolute">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <a class="navbar-brand font-tertiary" routerLink="/"><img src="../assets/images/logo.svg" alt="Riot Rant" /></a>
      <button [class.collapsed]="toogleMenu" (click)="toogleMenu = !toogleMenu; toogleProfile = false" class="navbar-toggler ms-auto" *ngIf="isLoggedIn === true">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse text-center" id="navigation" [class.show]="toogleMenu">
        <div class="triangle-menu"></div>
        <ul class="navbar-nav ms-auto">
          <ng-container *ngFor="let menu of menuArray">
            <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="menu.link" (click)="toogleMenu = false"> {{ menu.menu }}</a>
            </li>
          </ng-container>

<!--          <li class="nav-item d-none d-lg-block" *ngIf="isLoggedIn === false">-->
<!--            <a class="nav-link" href="" routerLink="featured-properties" (click)="toogleMenu = false">Featured Properties</a>-->
<!--          </li>-->

          <li class="nav-item d-none d-lg-block" *ngIf="isLoggedIn === false">
            <a class="SignUpBtn btn btn-theme ms-3" onclick="$('app-sidebar-login').toggle()" (click)="toogleMenu = false">Sign In/ Sign Up</a>
          </li>
          <li class="nav-item d-none d-lg-block profileDropDwn" *ngIf="isLoggedIn">
            <a *ngIf="!user?.profile_picture" class="userImgBtn btn ms-3" [class.collapsed]="toogleProfile" (click)="toogleProfile = !toogleProfile"
              ><img src="../assets/images/defaultProfile.webp" alt="user"
            /></a>
            <a *ngIf="user?.profile_picture" class="userImgBtn btn ms-3" [class.collapsed]="toogleProfile" (click)="toogleProfile = !toogleProfile"
              ><img [src]="user.profile_picture" alt="user"
            /></a>
            <div class="SubMenuToggle" [class.show]="toogleProfile">
              <div class="userInfo d-flex">
                <div class="triangle"></div>
                <div class="imgDropUser">
                  <a routerLink="/profile" class="p-0" *ngIf="user?.profile_picture"><img [src]="user.profile_picture" alt="user" /></a>
                  <a routerLink="/profile" class="p-0" *ngIf="!user?.profile_picture"><img src="../assets/images/defaultProfile.webp" alt="user" /></a>
                </div>
                <div class="infoUserDrp text-start ms-3">
                  <h5 class="mb-1">
                    <a class="textUrname" routerLink="/profile">{{ user.first_name }} {{ user.last_name }}</a>
                    <a routerLink="/profile" class="p-0 ms-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17.001" height="17.001" viewBox="0 0 17.001 17.001">
                        <path
                          id="edit"
                          d="M4780.5,541a8.5,8.5,0,1,1,8.5-8.5A8.509,8.509,0,0,1,4780.5,541Zm.506-11.2-4.5,4.5v1.528h1.528l4.5-4.5Zm1.468-1.3a.4.4,0,0,0-.287.119l-.745.745,1.527,1.528.745-.745a.406.406,0,0,0,0-.575l-.953-.953A.405.405,0,0,0,4782.474,528.5Z"
                          transform="translate(-4772 -524)"
                          fill="#ef1c25" />
                      </svg>
                    </a>
                  </h5>
                  <p class="mb-0">{{ user.email }}</p>
                </div>
              </div>
              <div class="itemMenuDrop text-start">
                <ng-container *ngFor="let profileMenu of headerProfileMenuArray">
                  <a routerLinkActive="active" *ngIf="profileMenu?.link" [routerLink]="profileMenu.link" (click)="toogleProfile = false">
                    <img src="../assets/images/{{ profileMenu.image }}" alt="{{ profileMenu.menu }}" />
                    <span class="ms-3">{{ profileMenu.menu }}</span></a
                  >
                  <a *ngIf="profileMenu?.actionName" (click)="profileMenu.actionName(); toogleProfile = false"
                    ><img src="../assets/images/{{ profileMenu.image }}" alt="{{ profileMenu.menu }}" /> <span class="ms-3">{{ profileMenu.menu }}</span></a
                  >
                </ng-container>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="nav-item d-lg-none d-xl-none" *ngIf="isLoggedIn">
        <a class="userImgBtn btn ms-3" [class.collapsed]="toogleProfile" (click)="toogleProfile = !toogleProfile; toogleMenu = false">
          <img src="{{ user.profile_picture }}" alt="user"
        /></a>
        <div class="SubMenuToggle" [class.show]="toogleProfile">
          <div class="userInfo d-flex">
            <div class="triangle"></div>
            <div class="imgDropUser">
              <a routerLink="/profile" class="p-0" *ngIf="user?.profile_picture"><img [src]="user.profile_picture" alt="user" /></a>
              <a routerLink="/profile" class="p-0" *ngIf="!user?.profile_picture"><img src="../assets/images/defaultProfile.webp" alt="user" /></a>
            </div>
            <div class="infoUserDrp text-start ms-3">
              <h5 class="mb-1">
                <a class="textUrname" routerLink="/profile">{{ user.first_name }} {{ user.last_name }}</a>
                <a routerLink="/profile" class="p-0 ms-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.001" height="17.001" viewBox="0 0 17.001 17.001">
                    <path
                      id="edit"
                      d="M4780.5,541a8.5,8.5,0,1,1,8.5-8.5A8.509,8.509,0,0,1,4780.5,541Zm.506-11.2-4.5,4.5v1.528h1.528l4.5-4.5Zm1.468-1.3a.4.4,0,0,0-.287.119l-.745.745,1.527,1.528.745-.745a.406.406,0,0,0,0-.575l-.953-.953A.405.405,0,0,0,4782.474,528.5Z"
                      transform="translate(-4772 -524)"
                      fill="#ef1c25" />
                  </svg>
                </a>
              </h5>
              <p class="mb-0">{{ user.email }}</p>
            </div>
          </div>
          <div class="itemMenuDrop text-start">
            <ng-container *ngFor="let profileMenu of headerProfileMenuArray">
              <a routerLinkActive="active" *ngIf="profileMenu?.link" [routerLink]="profileMenu.link" (click)="toogleProfile = false">
                <img src="../assets/images/{{ profileMenu.image }}" alt="{{ profileMenu.menu }}" />
                <span class="ms-3">{{ profileMenu.menu }}</span></a
              >
              <a class="" *ngIf="profileMenu?.actionName" (click)="profileMenu.actionName(); toogleProfile = false"
                ><img src="../assets/images/{{ profileMenu.image }}" alt="{{ profileMenu.menu }}" /> <span class="ms-3">{{ profileMenu.menu }}</span></a
              >
            </ng-container>
          </div>
        </div>
      </div>

      <div class="nav-item d-lg-none d-xl-none ms-2" *ngIf="isLoggedIn === false">
        <a class="SignUpBtn btn btn-theme" onclick="$('app-sidebar-login').toggle()">Sign In/ Sign Up</a>
      </div>
    </nav>
  </div>
</header>

<section
  class="hero-area herobanner-home d-flex align-items-center"
  [class.admin-panel]="isAdmin"
  id="parallax">
  <div class="container">
    <h1 class="text-center text-white">{{ name }}</h1>

    <div class="searcboxContent pt-lg-4 searchListing" *ngIf="isFilter === true">
      <form class="form-horizontal" [formGroup]="propertySearch" (ngSubmit)="propertySearchSubmit()">
        <div class="d-flex searchBox align-items-center">
          <div class="DropDwn mb-0 DropDwnType">
            <select class="form-control custom-select form-select" id="price_type" formControlName="price_type">
              <option value="">Rent</option>
              <ng-container *ngFor="let price_type of propertySetting['property_price']">
                <option>{{ price_type }}</option>
              </ng-container>
            </select>
          </div>
          <div class="DropDwn mb-0 DropDwnProType">
            <select class="form-control custom-select form-select" id="type" formControlName="property_type">
              <option value="">Property type</option>
              <ng-container *ngFor="let type of propertySetting['property_type']">
                <option>{{ type }}</option>
              </ng-container>
            </select>
          </div>
          <div class="DropDwn mb-0">
            <select class="form-control custom-select form-select" id="number_of_beds" formControlName="number_of_beds">
              <option value="">Bedrooms</option>
              <ng-container *ngFor="let number_of_bed of propertySetting['number_of_beds']">
                <option>{{ number_of_bed }}</option>
              </ng-container>
            </select>
          </div>
          <div class="DropDwn mb-0">
            <p class="form-control border-0 mb-0" *ngIf="!displayAvailableDate" (click)="displayAvailableDate = true">{{ f['available_date'].getRawValue() !== '' ? (f['available_date'].getRawValue() |  date: 'dd-MM-yyyy') : 'Available From' }}</p>
            <input
              *ngIf="displayAvailableDate"
              placeholder="Available From"
              type="date"
              class="form-control"
              id="available_date"
              formControlName="available_date" />
          </div>
          <div class="form-group mb-0">
            <select class="form-control custom-select form-select w-auto" id="radius" formControlName="radius">
              <option value="">Radius(miles)</option>
              <option value="0.5">0.5</option>
                <option *ngFor="let mile of miles" [value]="mile">{{ mile }}</option>
            </select>
          </div>
          <div class="form-group mb-0 w-100 searchFiled">
            <input placeholder="Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" formControlName="location" #search />
          </div>

          <div class="btnSearch ms-auto">
            <button href="#" class="btn btn-theme w-100" type="submit" [disabled]="propertySearch.invalid">
              <span class="me-2">Search</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19.003" height="19.007" viewBox="0 0 19.003 19.007">
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M25.8,24.209l-3.653-3.653a8.389,8.389,0,1,0-1.593,1.593L24.209,25.8A1.126,1.126,0,0,0,25.8,24.209ZM11.169,19.82a6.116,6.116,0,1,1,4.325,1.792,6.124,6.124,0,0,1-4.325-1.792Z"
                  transform="translate(-7.129 -7.125)"
                  fill="#fff" />
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

import { Component, OnInit,AfterViewInit, ViewChild,ElementRef,NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
import {adminLoggedInMenu, IsideBarMenu, loggedInMenu, loggedOutMenu} from '../../configs/menu.configs';
import { LocalStoreService } from '../../service/local-store.service';
import { ReusableClass } from '../../classes/ReusableClass';
import { filter } from 'rxjs';
import { PropertyService } from '../../../components/property/property.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IQuerySpecsDto, QuerySpecs } from "../../models/common/query.model";
import { MapService,Maps } from "../../service/map.service";
import { ICity } from "../../models/common/cityList";
import {LandingService} from "../../../components/landing/landing.service";

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
  providers: [ReusableClass],
})
export class HeaderHomeComponent implements OnInit,AfterViewInit {
  @ViewChild('search')
  public searchElementRef: ElementRef;
  /**
   * set Public variables
   * */
  miles: number[] = [];
  propertySearch: FormGroup;
  isLoggedIn = false;
  user;
  menuArray: { link: string; menu: string }[] = [];
  activeRouter: string;
  headerProfileMenuArray: IsideBarMenu[] = [];
  name = '';
  toogleMenu: boolean = false;
  toogleProfile: boolean = false;
  classToggled = false;
  query: IQuerySpecsDto=new QuerySpecs();
  cities:ICity[];
  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private _activeRoute: ActivatedRoute,
    private ls: LocalStoreService,
    private reusable: ReusableClass,
    private router: Router,
    private propertyService: PropertyService,
    private mapService: MapService,
    private ngZone: NgZone,
    private activeRoute: ActivatedRoute,
    private landingService: LandingService
  ) {
    for (let i = 1; i <= 5; i++) {
      this.miles.push(i);
    }
    this.cities =  this.activeRoute.snapshot.data['cities'];
    this.propertySearch = this._initForm();
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((res: any) => {
    //   console.log(res);
    //   this.name = this._getTitle();
    // });
    this._getCities();
  }

  /**
   * set title and filter data set on on init
   */
  ngOnInit(): void {
    this.toogleProfile=false;
    console.log(this.toogleProfile);
    this.activeRouter=this.router.url;
    this.name = this._getTitle();
    this._pageConfigs();
  }

  ngAfterViewInit(): void {
    if(this.searchElementRef != undefined) {
      this.mapService.api.then(maps => {
        this.initAutocomplete(maps);
      });
    }
  }
  initAutocomplete(maps: Maps) {
    const options = {
      componentRestrictions: { country: "uk" },
      strictBounds: true,
      types: ['address']
    };
    let autocomplete = new maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      options
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // const place=autocomplete.getPlace();
        let mainKey = Object.keys(autocomplete['gm_accessors_'].place)[0];
        const place = autocomplete.getPlace();
        this.f['lng'].patchValue(place.geometry.location.lng());
        this.f['lat'].patchValue(place.geometry.location.lat());
        // this.f['location'].patchValue(autocomplete.gm_accessors_.place.Kj.formattedPrediction);
        this.f['location'].patchValue(autocomplete['gm_accessors_'].place[mainKey].formattedPrediction);
        console.log(this.f['lng']);
      });
    });
  }
  getLatLong(placeid: string, map: any, fn) {
    let placeService = new google.maps.places.PlacesService(map);
    placeService.getDetails({
      placeId: placeid
    }, function (result, status) {
      console.log(result.geometry.location.lat());
      console.log(result.geometry.location.lng())
    });
  }

  public redirecTo() {
    let query = new QuerySpecs();
    this.propertyService.query$.next(query);
    this.router.navigate(['/filter']);
  }

  /**
   * toggle Menu close/open
   */
  public toggleField() {
    this.classToggled = !this.classToggled;
  }

  /**
   *
   * @private
   * page config for set header menu and all as per login user wise
   */
  private _pageConfigs() {
    this.activeRouter = this.router.url;

    const logout = () => {
      this.reusable.logout();
    };
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.user = this.ls.getItem('User');
      this.authService.user$.subscribe(user => {
        if (user.user_id) {
          this.user = user;
        } else {
          this.user = this.ls.getItem('User');
        }
      });

      if (!this.authService.isAdmin()) {
        this.menuArray = loggedInMenu;
        this.headerProfileMenuArray = [
          { link: '/my-interests', menu: 'Saved Properties', id: 'my_interests', image: 'interests.svg' },
         // { link: './change-password', menu: 'Change Password', id: 'change_password', image: 'change-password.svg' },
          { link: '', menu: 'Logout', id: 'logout', image: 'Logout.svg', actionName: logout },
        ];
      } else {
        this.menuArray = adminLoggedInMenu;
        this.headerProfileMenuArray = [
      //    { link: './change-password', menu: 'Change Password', id: 'change_password', image: 'change-password.svg' },
          { link: '', menu: 'Logout', id: 'logout', image: 'Logout.svg', actionName: logout },
        ];
      }
    } else {
      this.menuArray = loggedOutMenu;
    }
  }

  /**
   *
   * @private
   * get Title of page
   */

  private _getTitle(): string {
    if (this._activeRoute.root.firstChild.snapshot.data['name']) {
      return this._activeRoute.root.firstChild.snapshot.data['name'];
    } else {
      return this._activeRoute.root.children[0].snapshot.children[0]['data']['name'];
    }
  }

  /**
   * filter page form setup
   * @private
   */
  private _initForm(): FormGroup {
    let fg = this._fb.group({
      location: new FormControl('', []),
      city: new FormControl('', []),
      radius: new FormControl('', []),
      lng: new FormControl('', []),
      lat: new FormControl('', []),
    });
    return fg;
  }
  /**
   * create a get method for form
   */
  get f() {
    return this.propertySearch.controls;
  }
  /**
   * Search page form submit
   */
  public propertySearchSubmit() {
    this.query = new QuerySpecs();
    this.query.location =this.f['location'].value;
    this.query.radius =this.f['radius'].value;
    this.query.city =this.f['city'].value;
    this.query.lng =this.f['lng'].value;
    this.query.lat =this.f['lat'].value;
    this.propertyService.query$.next(this.query);
    this.router.navigate(['/filter']);
  }

  private _getCities() {
    this.landingService.getCities().subscribe((res) => {
      if(res.status === 1) {
        this.cities = res.data;
      }
    })
  }
}

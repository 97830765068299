import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../components/auth/auth.service';
import { AlertService } from '../service/alert/alert.service';
import { LocalStoreService } from '../service/local-store.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private ls: LocalStoreService
  ) {}
  canActivate() {
    if (this.authService.isLoggedOut()) {
      return true;
    } else {
      if (this.authService.isAdmin()) {
        this.router.navigate(['/admin/profile']);
      } else {
        this.router.navigate(['/profile']);
      }
      return false;
    }
    // route: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;
  }
}

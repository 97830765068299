import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar,MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    private _snackBar:MatSnackBar
  ) { }
  public successAlert(msg:string){
    this._snackBar.open(msg,'',{
      horizontalPosition:this.horizontalPosition,
      verticalPosition:this.verticalPosition,
      panelClass:['success'],
      duration:3000
    });
  }
  public errorAlert(msg:string){
    this._snackBar.open(msg,'',{
      horizontalPosition:this.horizontalPosition,
      verticalPosition:this.verticalPosition,
      panelClass:['error'],
      duration:5000
    });
  }
}

import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from './shared/service/alert/alert.module';
import { AuthGuard } from './shared/guards/auth.guard';
import { TokenInterceptor } from './shared/interceptors/token-interceptor.interceptor';
import { ErrorHandlerService } from './shared/service/error-handler.service';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { LoaderService } from './shared/service/loader.service';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { LogoutGuard } from './shared/guards/logout.guard';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from "../environments/environment";
import {AuthModule} from "./components/auth/auth.module";
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';

const config: SocketIoConfig = { url: environment.API_URL1, options: {} };

@NgModule({
  declarations: [AppComponent, TermsAndConditionsComponent],
  imports: [BrowserModule, SharedModule, AppRoutingModule, HttpClientModule, RouterModule, BrowserAnimationsModule, AlertModule, MatDialogModule,AuthModule,SocketIoModule.forRoot(config)],

  providers: [
    AuthGuard,
    LogoutGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    LoaderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from 'rxjs';
import {ChatSocketService} from "./shared/service/chat-socket.service";
import {LocalStoreService} from "./shared/service/local-store.service";
import {SharedInboxService} from "./components/chat/inbox/sharedInbox.service";
import {InboxService} from "./components/chat/inbox/inbox.service";
import {AuthService} from "./components/auth/auth.service";
import {RealtimeSocketService} from "./shared/service/realtime-socket.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'riotrent';
  header = undefined;
  footer= true;
  filter;
  isMessage=false;
  message='';
  messageFrom='';
  messageType=0;
  subscription: Subscription;
  subscriptionFlag: Subscription;
  subscriptionReal:Subscription;

  constructor(private authService:AuthService,private _route: Router, private _activeRoute: ActivatedRoute,private messageService: InboxService,
              private socket: ChatSocketService,private socketReal: RealtimeSocketService, private _ls: LocalStoreService,private _sharedService: SharedInboxService) {
    this._route.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((res: any) => {
        this.socketChat();
        if(this._activeRoute.root.firstChild.snapshot.data['header']){
          this.header = this._activeRoute.root.firstChild.snapshot.data['header'];
        }
        if(this._activeRoute.root.children[0].snapshot.children[0].data['header']!==undefined){
          this.header = this._activeRoute.root.children[0].snapshot.children[0].data['header'];
        }
        if(this._activeRoute.root.children[0].snapshot.data['hideAll']){
          this.header = undefined;
          this.footer = undefined;
        }else {
          this.footer = true;
        }

        if(_route.routerState.snapshot.url=='/chat'){
          this._sharedService.isChanges=false;
        }else{
          this._sharedService.isChanges=true;
        }
    });

  this.socketChat();
  }
  ngOnInit(): void {
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "rgb(21 30 45)"
        },
        button: {
          background: "rgb(239 28 37)",
          text: "#fff"
        }
      },
      theme: "classic",
      content: {
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        dismiss: 'Accept',
        link: 'Read More',
        href: "/pages/cookie-policy"
      }
    });
  }
  private socketChat(){
    if(this.authService.isLoggedIn()){
      this.socket.connectUser(this._ls.getItem('User').user_id);
    }

    if(this._sharedService.isChanges && this.authService.isLoggedIn() && !this.authService.isAdmin()){

      this.messageService.getConversationList().subscribe(result =>{
        if(result['status']){
          result.data.forEach((obj ,index)=> {
            let values = {sender_id: this._ls.getItem('User').user_id, receiver_id: obj.user_id};
            this.socket.emitUser(values);
          });
        }
      });

      // this.subscription=this.socket.getMessages().subscribe(message => {
      //
      // });

      this.subscriptionFlag=this.socket.sendNotificationFlag().subscribe(message => {
        if(this._sharedService.isChanges && this._ls.getItem('User').user_id!=message.data.sender_id){
          this.messageFrom=message.data.full_name;
          this.message=message.data.message;
          this.messageType=message.data.message_type;
          if(this.messageType==1){
            if(this.message.match(/\.(jpeg|jpg|gif|png)$/) != null){
              this.message=message.data.message;
            }else{
              this.messageType=1;
            }
          }
          this.isMessage=true;
          setTimeout(() => {
            this.isMessage = false;
          }, 8000);
        }
      });

    }
  }

  public isImage(url){
    if(url.match(/\.(jpeg|jpg|gif|png)$/) != null){
      return true
    }else{
      return false
    }
  }

  public hideNotification(){
    this.isMessage=false;
  }

  public chatOpen(){
    this._route.navigate(['/chat']);
  }


  ngOnDestroy() {
    // this.subscription?.unsubscribe();
    // this.subscriptionFlag?.unsubscribe();
  }
}

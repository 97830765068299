import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from "../../components/auth/auth.service";
import {LocalStoreService} from "../service/local-store.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private ls:LocalStoreService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var changedReq;
    var token=this.authService.token || this.authService.getToken();
    if(token){
      changedReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }else{
      changedReq=request;
    }
    return next.handle(changedReq);
  }
}

import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../components/auth/auth.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  data=new Date().getFullYear();
  constructor(private authService:AuthService) { }
  isLoggedIn=this.authService.isLoggedIn();
  isAdmin=this.authService.isAdmin();
  ngOnInit(): void {
  }

}

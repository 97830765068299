import { Component, NgModule, OnInit } from '@angular/core';
import { IsideBarMenu } from '../../configs/menu.configs';
import { AuthService } from '../../../components/auth/auth.service';
import { ReusableClass } from '../../classes/ReusableClass';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [ReusableClass],
})
export class SidebarComponent implements OnInit {
  menuSideBarArray: IsideBarMenu[] = [];
  activeRouter: string;
  constructor(private authService: AuthService, private reusable: ReusableClass, private router: Router) {}

  ngOnInit(): void {
    this._pageConfigs();
  }

  private _pageConfigs() {
    this.activeRouter = this.router.url;
    const logout = () => {
      this.reusable.logout();
    };

    const deleteAccount = () => {
      this.reusable.deleteAccount();
    };

    if (this.authService.isAdmin()) {
      this.menuSideBarArray = [
        { link: '/admin/dashboard', menu: 'Dashboard', id: 'edit_profile', image: 'dashboard.svg' },
        { link: '/admin/profile', menu: 'Profile', id: 'edit_profile', image: 'edit.svg' },
        { link: '/admin/property', menu: 'Property', id: 'property', image: 'property-icon.svg' },
        { link: '/admin/user', menu: 'User Management', id: 'property', image: 'user.svg' },
        { link: '/admin/bid-management', menu: 'Bid Management', id: 'property', image: 'award-bid-card.svg' },
        { link: '/admin/transactions', menu: 'Transactions', id: 'transactions', image: 'transactions.svg' },
        { link: '/admin/setting', menu: 'Settings', id: 'settings', image: 'settings.svg' },
        //    { link: '/', menu : 'User',id:"user",image:"edit.svg" },
        //   { link: '/admin/subscription', menu : 'Subscription',id:"subscription",image:"subscription.svg"},
      //  { link: '/admin/change-password', menu: 'Change Password', id: 'change_password', image: 'change-password.svg' },
        { link: '', menu: 'Logout', id: 'logout', image: 'Logout.svg', actionName: logout },
      ];
    } else {
      this.menuSideBarArray = [
        { link: '/profile', menu: 'Profile', id: 'edit_profile', image: 'edit.svg' },
        { link: '/my-interests', menu: 'Saved Properties', id: 'my_interests', image: 'interests.svg' },
        // { link: '/my-subscription', menu: 'Subscription', id: 'my_subscription', image: 'subscription.svg' },
      //  { link: '/change-password', menu: 'Change Password', id: 'change_password', image: 'change-password.svg' },
     //   { link: '/change-password', menu: 'Change Password', id: 'change_password', image: 'change-password.svg' },
        { link: '/property/list', menu: 'My listing', id: 'my_listing', image: 'list.svg' },
        { link: '/property/my-bids', menu: 'My Bids', id: 'my_bids', image: 'bid-card.svg' },
        { link: '/chat', menu: 'Chat', id: 'chat', image: 'chat.svg' },
     //   { link: '/transactions', menu: 'Transactions', id: 'transactions', image: 'transactions.svg' },
      //  { link: '', menu: 'Delete Account', id: 'delete_account', image: 'delete-account.svg', actionName: deleteAccount },
        { link: '', menu: 'Logout', id: 'logout', image: 'Logout.svg', actionName: logout },
      ];
    }
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://riotrentapi.sandbox-dev.co.uk:9000/v1',
  API_URL1: 'https://riotrentapi.sandbox-dev.co.uk:9000',
  // API_URL: 'http://localhost:3000/v1',
  // API_URL1: 'http://localhost:3000',
  AWS_ACCESS_KEY: 'AKIAVK546CJFJGJPV6HC',
  AWS_SECRET_KEY: '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',
  AWS_REGION: 'eu-west-2',
  AWS_BUCKET: 'riot-rent-staging',
  STRIPE_SECRET_KEY: 'sk_test_51Mc5udSDTdHDJfZoxSwSH8wwCqRLmAR76dWHp456KOSAFYiAKzxyDI4My6a2J0E09aGJOD25sJYxiv5dAaodoZI300oqniiEpD'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { HeaderHomeComponent } from './header-home/header-home.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { SharedMaterialModule } from '../shared-material.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { MapService } from '../service/map.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RejectBidDialogComponent } from './reject-bid-dialog/reject-bid-dialog.component';

const components = [
  HeaderComponent,
  FooterComponent,
  PropertyListComponent,
  ConfirmationDialogComponent,
  HeaderHomeComponent,
  LoaderComponent,
  SidebarComponent,
  RejectBidDialogComponent,
];
@NgModule({
  declarations: components,
  exports: components,
  imports: [RouterModule, CommonModule, ReactiveFormsModule, SharedMaterialModule, SharedPipesModule],
  providers: [MapService],
})
export class ComponentsModule {}

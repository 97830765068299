import { Pipe, PipeTransform } from '@angular/core';
/*
 * Replace the the first paremeter with the second parameter
*/
@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
  transform(value: string, existing: string, latest: string): string {
    return  value.replace(new RegExp('\\'+existing, 'g'), latest);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ComponentsModule} from "./components/components.module";
import { SharedPipesModule } from './pipes/shared-pipes.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ComponentsModule
  ],
  exports: [ComponentsModule, SharedPipesModule],
})
export class SharedModule { }

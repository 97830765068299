export interface IUserDetailDto {
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  token: string;
  user_type: number;
  profile_picture: string;
}
export class UserDetailDto implements IUserDetailDto {
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  token: string;
  user_type: number;
  profile_picture: string;
}

import {AuthService} from "../../components/auth/auth.service";
import {MatDialog} from "@angular/material/dialog";

import {
  ConfirmationDialogComponent,
  ConfirmDialogModel
} from "../components/confirmation-dialog/confirmation-dialog.component";
import {Injectable} from "@angular/core";

@Injectable()
export class ReusableClass {

  constructor(private authService:AuthService,private dialog:MatDialog) {
  }

  public const
  logout = () => {
    const dialogData = new ConfirmDialogModel('Are you sure you want to logout?', '',"logout-info.svg");
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
      panelClass: 'OverFlowCR',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.authService.signout();
      }
    });
  }

  deleteAccount = () => {
    const dialogData = new ConfirmDialogModel('Delete Account?', 'Are you sure, you want to delete your account?',"delete-info.svg");

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
      panelClass: 'OverFlowCR',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.authService.delete();
      }
    });
  }


}

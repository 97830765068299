import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { API_END_POINT } from '../../shared/configs/api.config';
import { ApiHttpService } from '../../shared/service/api-http.service';
import { map, catchError } from 'rxjs/operators';
import { IUserDetailDto, UserDetailDto } from '../../shared/models/user/user-detail';
import { LocalStoreService } from '../../shared/service/local-store.service';
import { AlertService } from '../../shared/service/alert/alert.service';
import { Router } from '@angular/router';
import { IResponseDto } from '../../shared/models/api-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: string;
  signingIn: Boolean;
  isAuthenticated: Boolean;
  user: IUserDetailDto = new UserDetailDto();
  user$ = new BehaviorSubject<IUserDetailDto>(this.user);
  TOKEN = 'Token';
  userType = 'userType';

  constructor(
    private apiHttpService: ApiHttpService,
    private ls: LocalStoreService,
    private router: Router,
    private AlertService: AlertService
  ) {}

  /**
   * signup api call
   */
  public signUp(user: any): Observable<IResponseDto<any>> {
    return this.apiHttpService.post(API_END_POINT.auth.signup, user);
  }

  /**
   * sign in api call
   */
  public signIn(user: any): Observable<IResponseDto<any>> {
    this.signingIn = true;
    return this.apiHttpService.post(API_END_POINT.auth.login, user).pipe(
      map((res: any) => {
        if (res.status == 1) {
          // call function set userdata in local storage if success
          this.setUserAndToken(res.data, !!res);
          this.signingIn = false;
        }
        return res;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /**
   * signout function
   */
  public signout(status?: number) {
    if (status) {
      this.ls.clear();
      this.user$.complete();
      this.router.navigate(['auth/login']);
    } else {
      this.logout().subscribe(res => {
        if (res.status === 1) {
          this.AlertService.successAlert(res.message);
          this.ls.clear();
          this.user$.complete();
          this.router.navigate(['auth/login']);
        }
      });
    }
  }

  /**
   * delete account function
   */
  public delete() {
    this.deleteAccount().subscribe(res => {
      if (res.status === 1) {
        this.AlertService.successAlert(res.message);
        this.ls.clear();
        this.user$.complete();
        this.router.navigate(['auth/login']);
      }
    });
  }
  public deleteAccount() {
    return this.apiHttpService.get(API_END_POINT.user.delete);
  }
  /**
   * Logout API call
   */
  public logout(): Observable<IResponseDto<any>> {
    let data = {
      token: this.token,
    };
    return this.apiHttpService.post(API_END_POINT.auth.logout, data);
  }

  /**
   * Forgot Password
   */
  public forgotPassword(user: any): Observable<IResponseDto<any>> {
    return this.apiHttpService.post(API_END_POINT.auth.forgot_password, user);
  }

  /**
   * Reset Password
   */
  public resetPassword(user: any): Observable<any> {
    return this.apiHttpService.post(API_END_POINT.auth.reset_password, user);
  }

  /**
   * check is Logged In
   */
  isLoggedIn(): Boolean {
    return this.getToken();
  }

  /**
   * get logged in user toke
   */
  getToken() {
    return this.ls.getItem(this.TOKEN);
  }

  /**
   * check user logged in is admin or not
   */
  isAdmin(): Boolean {
    return this.getUserType() ? true : false;
  }
  getUserType() {
    return this.ls.getItem(this.userType);
  }
  /**
   * check user logged out or not
   */
  isLoggedOut(): Boolean {
    let token = this.ls.getItem('Token');
    let user = this.ls.getItem('User');
    if (token && user) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * set session and local storage for user dto after login
   */
  setUserAndToken(user: IUserDetailDto, isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = user.token;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.TOKEN, this.token);
    if (this.user.user_type === 1) {
      this.ls.setItem(this.userType, true);
    } else {
      this.ls.setItem(this.userType, false);
    }
    this.ls.setItem('User', user);
  }
}

export interface IQuerySpecsDto {
  limit: number;
  offset: number;
  search: string;
  city: string;
  sorted_by: string;
  order: string;
  property_type?: string;
  number_of_beds?: string;
  available_date?: string;
  price_type?: string;
  location?: string;
  is_featured?: number;
  user_id?: string;
  property_id?: number;
  status?:number;
  lng: number;
  lat: number;
  radius?: string;
}

export class QuerySpecs implements IQuerySpecsDto {
  limit: number = 8;
  offset: number = 0;
  search: string = '';
  city: string = '';
  sorted_by: string;
  order: string;
  property_type: string = '';
  number_of_beds: string = '';
  available_date: string = '';
  price_type: string = '';
  location: string = '';
  is_featured: number = 0;
  user_id: string = '';
  property_id?: number;
  lng: number;
  lat: number;
  radius: string = '';
  status?:number;
}

import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../../service/alert/alert.service';

@Component({
  selector: 'app-reject-bid-dialog',
  templateUrl: './reject-bid-dialog.component.html',
  styleUrls: ['./reject-bid-dialog.component.scss'],
})
export class RejectBidDialogComponent {
  reasonForReject: FormControl = new FormControl('', [Validators.required]);
  constructor(private dialogRef: MatDialogRef<RejectBidDialogComponent>, private alertService: AlertService) {}
  public onConfirm() {
    if (this.reasonForReject?.errors?.['required']) {
      this.alertService.errorAlert('Please enter comment');
      return;
    } else {
      this.dialogRef.close(this.reasonForReject.getRawValue());
    }
  }
  public onCancel() {
    this.dialogRef.close(false);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_END_POINT } from 'src/app/shared/configs/api.config';
import { IResponseDto } from 'src/app/shared/models/api-response';
import { IInboxList } from 'src/app/shared/models/chat/inboxList.model';
import { IMessageListDto } from 'src/app/shared/models/chat/messageList.model';
import { IMessageQueryDto } from 'src/app/shared/models/chat/messageQuery.model';
import { ApiHttpService } from 'src/app/shared/service/api-http.service';

@Injectable({
  providedIn: 'root',
})
export class InboxService {
  userDetails;
  constructor(private _apiHttpService: ApiHttpService) {}

  public getConversationList(): Observable<IResponseDto<IInboxList[]>> {
    return this._apiHttpService.get(API_END_POINT.chat.list);
  }

  public getMessages(query: any): Observable<IResponseDto<IMessageListDto[]>> {
    query = Object.keys(query)
      .map(key => key + '=' + query[key])
      .join('&');
    return this._apiHttpService.get(API_END_POINT.chat.detail + '?' + query);
  }

  public sendMessage(data): Observable<IResponseDto<any>> {
    return this._apiHttpService.post(API_END_POINT.chat.sendMessage, data)
  }

  public ReportMessage(data): Observable<IResponseDto<any>> {
    return this._apiHttpService.post(API_END_POINT.chat.reportMessage, data)
  }

  public readConversationMessages(data): Observable<IResponseDto<any>> {
    return this._apiHttpService.post(API_END_POINT.chat.readConversationMessages, data)
  }

}

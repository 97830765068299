import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/service/alert/alert.service';
import {SharedInboxService} from "../../chat/inbox/sharedInbox.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  //#region public variables
  loginForm: FormGroup;
  //#endregion public variables

  constructor(private authService: AuthService, private router: Router, private alert: AlertService,private _sharedService: SharedInboxService) {}

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8),Validators.maxLength(100)]),
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  public signIn() {
    if (this.loginForm.valid) {
      let data = {
        email: this.f['email'].value,
        password: this.f['password'].value,
      };
      this.authService.signIn(data).subscribe((res: any) => {
        if (res.status) {
          this.alert.successAlert(res.message);
          if(res.data.user_type){
            this.router.navigate(['/admin/dashboard']);
          } else{
            this.router.navigate(['/']);
          }
        } else {
          this.alert.errorAlert(res.message);
        }
      });
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedInboxService {
  public userDetails;
  public isChanges=true;
  public conversationList;
}

import { Injectable } from '@angular/core';

import { Socket } from 'ngx-socket-io';
import {Observable, of} from 'rxjs';
import test from "node:test";
import {IMessageListDto} from "../models/chat/messageList.model";

@Injectable({
  providedIn: 'root'
})

export class RealtimeSocketService {

  constructor(private socket: Socket) { }

  connectProperty(property_id):Observable<any>  {
    let data= {
      property_id: property_id
    };
    let success = this.socket.emit('connectProperty', data);
    this.connectPropertyEmitOn()
    return success;
  }

  connectPropertyEmitOn() {
    return of( this.socket.on('connectProperty',function (data) {
      return data;
    }));
  }

  propertyChange(id):Observable<any> {
    return of(this.socket.emit('propertyChange', id));
    // this.sendMessageEmitOn()
  }
  propertyUpdate(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('propertyUpdate', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    })
  }

}
